import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid, InputLabel, Select, MenuItem, Input, Divider, Typography, List, ListItem, Checkbox, ListItemText, FormControl } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { Supplier } from 'src/types/supplierTypes';
import { CountryAllRequest } from 'src/actions/countryActions';
import { useDispatch } from 'react-redux';
import { Country } from 'src/types/countryTypes';
import { LoadingButton } from '@mui/lab';
import { Client } from 'src/types/clientTypes';
import { ClientAllRequestData } from 'src/actions/clientActions';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

const ITEM_HEIGHT: number = 48;
const ITEM_PADDING_TOP: number = 8;
const MenuProps: any = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export interface FormValues {
    initialValues: Supplier,
    submitForm: any,
}

export default function SupplierForm({ initialValues, submitForm }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        supplierType: Yup.number().required('Supplier type  is required'),
        supplierShortCode: Yup.string().trim().required('Shortcode  is required'),
        countryId: Yup.string().trim().required('Country  is required'),
        supplierName: Yup.string().min(4).trim().required('Supplier name  is required'),
        supplierEmail: Yup.string().required('Supplier email  is required'),
        supplierRuleCPI: Yup.string().required('Supplier rule   is required'),
        redirectComplete: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectQuotaFull: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectTerminate: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectQualityTerm: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        // redirectSurveyDuplicate: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectSurveyClose: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { redirectTo } = useSelector(state => state.supplier);
    const { countries } = useSelector(state => state.country);
    const { clients } = useSelector(state => state.client);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])


    useEffect(() => {
        dispatch(CountryAllRequest())
        dispatch(ClientAllRequestData(""));
    }, [])

    const handleChange = (event:any,formikProps:any) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          formikProps.setFieldValue('clientId',formikProps.values.clientId.length === clients.length ? [] : clients.map(sup=>sup._id));
          return;
        }
        formikProps.setFieldValue('clientId',value);
      };


    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    supplierType: initialValues?.supplierType,
                    supplierName: initialValues?.supplierName || '',
                    supplierShortCode: initialValues?.supplierShortCode || '',
                    supplierPhoneNumber: initialValues?.supplierPhoneNumber || '',
                    supplierEmail: initialValues?.supplierEmail || '',
                    supplierWebsite: initialValues?.supplierWebsite || '',
                    city: initialValues?.city || '',
                    state: initialValues?.state || '',
                    company: initialValues?.company || '',
                    address1: initialValues?.address1 || '',
                    address2: initialValues?.address2 || '',
                    zipCode: initialValues?.zipCode || '',
                    netDays: initialValues?.netDays || 30,
              //      wolfAccount: initialValues?.wolfAccount || '',
                    apiClientId: initialValues?.apiClientId || '',
                    hashKey: initialValues?.hashKey || '',
                    supplierPanelSize: initialValues?.supplierPanelSize || '',
                    supplierRuleCPI: initialValues?.supplierRuleCPI || '',
                    countryId: initialValues?.countryId,
                    supplierApiKey: initialValues?.supplierApiKey || '',
                    apiBody: initialValues?.apiBody || '',
                    apiKey: initialValues?.apiKey || '',
                    apiUrl: initialValues?.apiUrl || '',
                    apiHeader:initialValues?.apiHeader || '',
                    variableMapping:initialValues?.variableMapping || '',
                    identifierMapping:initialValues?.identifierMapping || '',
                    redirectComplete: initialValues?.redirectComplete || '',
                    redirectQuotaFull: initialValues?.redirectQuotaFull || '',
                    redirectTerminate: initialValues?.redirectTerminate || '',
                    redirectQualityTerm: initialValues?.redirectQualityTerm || '',
                    redirectSurveyClose: initialValues?.redirectSurveyClose || '',
                    clientId: initialValues?.clientId || [],
                    isEmailNotification: initialValues?.isEmailNotification,
                    isPreScreen: initialValues?.isPreScreen || false,
                    supplierApprovedHook: initialValues?.supplierApprovedHook || '',
                }}
            >
                {(formikProps: FormikProps<Supplier>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Personal Details</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        error={Boolean(formikProps.touched.supplierType && formikProps.errors.supplierType)}
                                        fullWidth
                                        helperText={formikProps.touched.supplierType && formikProps.errors.supplierType}
                                        label="Supplier Type"
                                        {...formikProps.getFieldProps('supplierType')}
                                        variant="standard"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option key="" value="">
                                            {"Select Type"}
                                        </option>
                                        <option value={1}>
                                            DIY
                                        </option>
                                        <option value={2}>
                                            Local
                                        </option>
                                    </TextField>
                                </Grid>

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.supplierShortCode && formikProps.errors.supplierShortCode)}
                                        fullWidth
                                        helperText={formikProps.touched.supplierShortCode && formikProps.errors.supplierShortCode}
                                        label="Supplier ShortCode"
                                        {...formikProps.getFieldProps('supplierShortCode')}
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.supplierName && formikProps.errors.supplierName)}
                                        fullWidth
                                        helperText={formikProps.touched.supplierName && formikProps.errors.supplierName}
                                        label="Supplier name"
                                        {...formikProps.getFieldProps('supplierName')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        label="Country Name"
                                        error={Boolean(formikProps.touched.countryId && formikProps.errors.countryId)}
                                        fullWidth
                                        helperText={formikProps.touched.countryId && formikProps.errors.countryId}
                                        {...formikProps.getFieldProps('countryId')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Country"}
                                        </option>
                                        {countries.map((country: Country) => (
                                            <option key={country._id} value={country._id}>
                                                {country.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.supplierPhoneNumber && formikProps.errors.supplierPhoneNumber)}
                                        fullWidth
                                        helperText={formikProps.touched.supplierPhoneNumber && formikProps.errors.supplierPhoneNumber}
                                        label="Contact Number"
                                        {...formikProps.getFieldProps('supplierPhoneNumber')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.supplierEmail && formikProps.errors.supplierEmail)}
                                        fullWidth
                                        helperText={formikProps.touched.supplierEmail && formikProps.errors.supplierEmail}
                                        label="Supplier Email Address"
                                        {...formikProps.getFieldProps('supplierEmail')}
                                        type="email"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.supplierWebsite && formikProps.errors.supplierWebsite)}
                                        fullWidth
                                        helperText={formikProps.touched.supplierWebsite && formikProps.errors.supplierWebsite}
                                        label="Supplier Website Url"
                                        {...formikProps.getFieldProps('supplierWebsite')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.company && formikProps.errors.company)}
                                        fullWidth
                                        {...formikProps.getFieldProps('company')}
                                        helperText={formikProps.touched.company && formikProps.errors.company}
                                        label="Company"
                                        name="company"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        error={Boolean(formikProps.touched.address1 && formikProps.errors.address1)}
                                        fullWidth
                                        {...formikProps.getFieldProps('address1')}
                                        helperText={formikProps.touched.address1 && formikProps.errors.address1}
                                        label="Address Line 1"
                                        name="address1"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        error={Boolean(formikProps.touched.address2 && formikProps.errors.address2)}
                                        fullWidth
                                        {...formikProps.getFieldProps('address2')}
                                        helperText={formikProps.touched.address2 && formikProps.errors.address2}
                                        label="Address Line 2"
                                        name="address2"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.city && formikProps.errors.city)}
                                        fullWidth
                                        {...formikProps.getFieldProps('city')}
                                        helperText={formikProps.touched.city && formikProps.errors.city}
                                        label="City"
                                        name="city"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.state && formikProps.errors.state)}
                                        fullWidth
                                        {...formikProps.getFieldProps('state')}
                                        helperText={formikProps.touched.state && formikProps.errors.state}
                                        label="State"
                                        name="state"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.zipCode && formikProps.errors.zipCode)}
                                        fullWidth
                                        {...formikProps.getFieldProps('zipCode')}
                                        helperText={formikProps.touched.zipCode && formikProps.errors.zipCode}
                                        label="Zip Code"
                                        name="zipCode"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>


                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        error={Boolean(formikProps.touched.netDays && formikProps.errors.netDays)}
                                        fullWidth
                                        {...formikProps.getFieldProps('netDays')}
                                        helperText={formikProps.touched.netDays && formikProps.errors.netDays}
                                        label="Net Days"
                                        name="netDays"
                                        type="text"
                                        variant="standard"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                        <option key={30} value={30}>
                                            30
                                        </option>
                                        <option key={45} value={45}>
                                            45
                                        </option>
                                        <option key={60} value={60}>
                                            60
                                        </option>
                                    </TextField>
                                </Grid>
                                {/* <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        error={Boolean(formikProps.touched.wolfAccount && formikProps.errors.wolfAccount)}
                                        fullWidth
                                        {...formikProps.getFieldProps('wolfAccount')}
                                        helperText={formikProps.touched.wolfAccount && formikProps.errors.wolfAccount}
                                        label="Wolf Account"
                                        name="wolfAccount"
                                        type="text"
                                        variant="standard"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                        <option value="wcanada">
                                            Wolf Canada
                                        </option>
                                        <option value="windia">
                                            Wolf India
                                        </option>

                                    </TextField>
                                </Grid> */}
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.redirectComplete && formikProps.errors.redirectComplete)}
                                        fullWidth
                                        helperText={(formikProps.touched.redirectComplete && formikProps.errors.redirectComplete) || 'Link eg. thanks?status=c&uid=[uid]'}
                                        label="Complete"
                                        {...formikProps.getFieldProps('redirectComplete')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.redirectTerminate && formikProps.errors.redirectTerminate)}
                                        fullWidth
                                        helperText={(formikProps.touched.redirectTerminate && formikProps.errors.redirectTerminate) || 'Link eg. thanks?status=q&uid=[uid]'}
                                        label="Terminate"
                                        {...formikProps.getFieldProps('redirectTerminate')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>


                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.redirectQuotaFull && formikProps.errors.redirectQuotaFull)}
                                        fullWidth
                                        helperText={(formikProps.touched.redirectQuotaFull && formikProps.errors.redirectQuotaFull) || 'Link eg. thanks?status=q&uid=[uid]'}
                                        label="Over Quota"
                                        {...formikProps.getFieldProps('redirectQuotaFull')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>


                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.redirectQualityTerm && formikProps.errors.redirectQualityTerm)}
                                        fullWidth
                                        helperText={(formikProps.touched.redirectQualityTerm && formikProps.errors.redirectQualityTerm) || 'Link eg. thanks?status=q&uid=[uid]'}
                                        label="Quality Term"
                                        {...formikProps.getFieldProps('redirectQualityTerm')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                {/* <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.redirectSurveyDuplicate && formikProps.errors.redirectSurveyDuplicate)}
                                        fullWidth
                                        helperText={(formikProps.touched.redirectSurveyClose && formikProps.errors.redirectSurveyDuplicate) || 'Link eg. thanks?status=q&uid=[uid]'}
                                        label="Survey Duplicate"
                                        {...formikProps.getFieldProps('redirectSurveyDuplicate')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid> */}
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.redirectSurveyClose && formikProps.errors.redirectSurveyClose)}
                                        fullWidth
                                        helperText={(formikProps.touched.redirectSurveyClose && formikProps.errors.redirectSurveyClose) || 'Link eg. thanks?status=q&uid=[uid]'}
                                        label="Survey Close"
                                        {...formikProps.getFieldProps('redirectSurveyClose')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.supplierApiKey && formikProps.errors.supplierApiKey)}
                                        fullWidth
                                        helperText={(formikProps.touched.supplierApiKey && formikProps.errors.supplierApiKey)}
                                        label="Supplier Api Key"
                                        disabled
                                        {...formikProps.getFieldProps('supplierApiKey')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Settings</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <List>
                                        <ListItem dense>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                checked={formikProps.values.isPreScreen}
                                                {...formikProps.getFieldProps('isPreScreen')}
                                            />
                                            <ListItemText primary={`Prescreen`} />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <List>
                                        <ListItem dense>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                checked={formikProps.values.isEmailNotification}
                                                {...formikProps.getFieldProps('isEmailNotification')}
                                            />
                                            <ListItemText primary={`Email Notification`} />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Integration</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiUrl && formikProps.errors.apiUrl)}
                                        fullWidth
                                        helperText={formikProps.touched.apiUrl && formikProps.errors.apiUrl}
                                        label="API URL"
                                        {...formikProps.getFieldProps('apiUrl')}
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiKey && formikProps.errors.apiKey)}
                                        fullWidth
                                        helperText={formikProps.touched.apiKey && formikProps.errors.apiKey}
                                        label="Secret key"
                                        {...formikProps.getFieldProps('apiKey')}
                                        variant="standard"
                                    />
                                </Grid>


                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.hashKey && formikProps.errors.hashKey)}
                                        fullWidth
                                        helperText={formikProps.touched.hashKey && formikProps.errors.hashKey}
                                        label="Hash key"
                                        {...formikProps.getFieldProps('hashKey')}
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiClientId && formikProps.errors.apiClientId)}
                                        fullWidth
                                        helperText={formikProps.touched.apiClientId && formikProps.errors.apiClientId}
                                        label="Api Client Id"
                                        {...formikProps.getFieldProps('apiClientId')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiHeader && formikProps.errors.apiHeader)}
                                        fullWidth
                                        helperText={formikProps.touched.apiHeader && formikProps.errors.apiHeader}
                                        label="Api Header"
                                        {...formikProps.getFieldProps('apiHeader')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Variable Mapping</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        minRows={10}
                                        error={Boolean(formikProps.touched.variableMapping && formikProps.errors.variableMapping)}
                                        fullWidth
                                        {...formikProps.getFieldProps('variableMapping')}
                                        helperText={formikProps.touched.variableMapping && formikProps.errors.variableMapping}
                                        label="Variable Mapping"
                                        name="variableMapping"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        minRows={10}
                                        error={Boolean(formikProps.touched.identifierMapping && formikProps.errors.identifierMapping)}
                                        fullWidth
                                        {...formikProps.getFieldProps('identifierMapping')}
                                        helperText={formikProps.touched.identifierMapping && formikProps.errors.identifierMapping}
                                        label="Identifier Mapping"
                                        name="identifierMapping"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Supplier Mapping</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Client</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={formikProps.values.clientId}
                                            onChange={(e) =>handleChange(e,formikProps) }
                                            input={<Input placeholder="Clients" />}
                                            renderValue={(selected) => {
                                                return clients.filter((x: any) => { if (selected.includes(x._id)) return x }).map((x: Client) => x.label).join(' ,');
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value="all" >
                                                <>
                                                    <Checkbox  checked={clients.length > 0 && formikProps.values.clientId.length === clients.length}/>
                                                    <ListItemText  secondary="Select All" />
                                                </>
                                            </MenuItem>
                                            {clients.map((client: any) => (
                                                <MenuItem key={client._id} value={client._id}>
                                                    <>
                                                        <Checkbox
                                                            checked={
                                                                formikProps.values && formikProps.values.clientId.findIndex((rid: string) => rid === client._id) >= 0
                                                            }
                                                        />
                                                        <ListItemText primary={client.label} />
                                                    </>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Supplier Rules</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.supplierRuleCPI && formikProps.errors.supplierRuleCPI)}
                                        fullWidth
                                        helperText={formikProps.touched.supplierRuleCPI && formikProps.errors.supplierRuleCPI || 'Link eg. 2.5|3.4|4|6'}
                                        label="Supplier Rule CPI"
                                        {...formikProps.getFieldProps('supplierRuleCPI')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Supplier Hooks</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.supplierApprovedHook && formikProps.errors.supplierApprovedHook)}
                                        fullWidth
                                        helperText={formikProps.touched.supplierApprovedHook && formikProps.errors.supplierApprovedHook }
                                        label="Supplier Approved"
                                        {...formikProps.getFieldProps('supplierApprovedHook')}
                                        variant="standard"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {initialValues ? 'Save Supplier' : 'Create Supplier'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}

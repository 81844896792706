import { styled } from '@mui/material/styles';
import { Card, Stack,  Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Editor } from '@tinymce/tinymce-react';
import {  useRef } from 'react';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface FormValues {
    submitForm: any,
    initialValues:any
}

export default function EmailForm({ submitForm,initialValues }: FormValues) {

    const editorRef: any = useRef(null);
    const SchemaCategoryForm = Yup.object().shape({
        emailBody: Yup.string().required('Body is required'),
    });

    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    projectId:initialValues.projectId,
                    emailBody: '',
                }}
            >
                {(formikProps: FormikProps<any>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Editor
                                        apiKey='hjckgihh198u8grz87gzmg8otea07eg8wb0henzchkpt5ag2'
                                        initialValue=""
                                        onEditorChange={(newText) => formikProps.setFieldValue('emailBody', newText)}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {'Send Email'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}

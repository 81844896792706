import { useEffect } from 'react';
import * as Yup from 'yup'
import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid, InputLabel, Select, MenuItem, Input, Divider, Typography, List, ListItem, Checkbox, ListItemText, FormControl } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { Client } from 'src/types/clientTypes';
import { CountryAllRequest } from 'src/actions/countryActions';
import { useDispatch } from 'react-redux';
import { SupplierAllRequestData } from 'src/actions/supplierActions';
import { UserAllRequestBdeData } from 'src/actions/userActions';
import { Supplier } from 'src/types/supplierTypes';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));
const ITEM_HEIGHT: number = 48;
const ITEM_PADDING_TOP: number = 8;
const MenuProps: any = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export interface SubmitFormValues {
    initialValues: Client,
    submitForm: any,
}

export default function ClientForm({ initialValues, submitForm }: SubmitFormValues) {

    const dispatch = useDispatch();
    const SchemaClientForm = Yup.object().shape({
        clientName: Yup.string().min(4).trim().required('Client name  is required'),
        countryId: Yup.string().trim().required('Country is required')
    });
    const navigate = useNavigate();

    const { redirectTo } = useSelector(state => state.client);
    const { countries } = useSelector(state => state.country);
    const { suppliers } = useSelector(state => state.supplier);
    const { users } = useSelector(state => state.user);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])


    useEffect(() => {
        dispatch(CountryAllRequest())
        dispatch(SupplierAllRequestData());
        dispatch(UserAllRequestBdeData());
    }, [])


    const handleChange = (event:any,formikProps:any) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          formikProps.setFieldValue('supplierId',formikProps.values.supplierId.length === suppliers.length ? [] : suppliers.map(sup=>sup._id));
          return;
        }
        formikProps.setFieldValue('supplierId',value);
      };


    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaClientForm}
                initialValues={{
                    clientName: initialValues?.clientName || '',
                    clientContactName: initialValues?.clientContactName || '',
                    clientPhoneNumber: initialValues?.clientPhoneNumber || '',
                    clientEmail: initialValues?.clientEmail || '',
                    clientWebsite: initialValues?.clientWebsite || '',
                    countryId: initialValues?.countryId,
                    city: initialValues?.city || '',
                    state: initialValues?.state || '',
                    company: initialValues?.company || '',
                    address1: initialValues?.address1 || '',
                    address2: initialValues?.address2 || '',
                    zipCode: initialValues?.zipCode || '',
                    netDays: initialValues?.netDays || 30,
                 //   wolfAccount: initialValues?.wolfAccount || '',
                    apiUrl: initialValues?.apiUrl || '',
                    apiKey: initialValues?.apiKey || '',
                    apiHeader: initialValues?.apiHeader || '',
                    apiFunctionHeader: initialValues?.apiFunctionHeader || '',
                    apiBody: initialValues?.apiBody || '',
                    apiResponseVar: initialValues?.apiResponseVar || '',
                    apiFunction: initialValues?.apiFunction || '',
                    apiParams: initialValues?.apiParams || '',
                    variableMapping: initialValues?.variableMapping || '',
                    identifierMapping: initialValues?.identifierMapping || '',
                    supplierId: initialValues?.supplierId || [],
                    clientApiKey: initialValues?.clientApiKey || '',
                    isApiIntegration: initialValues?.isApiIntegration || false,
                    isScreenUrlIntegration: initialValues?.isScreenUrlIntegration || false,
                    assignedTo:initialValues?.assignedTo || '',
                }}
            >
                {(formikProps: FormikProps<Client>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Personal Details</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.clientName && formikProps.errors.clientName)}
                                        fullWidth
                                        helperText={formikProps.touched.clientName && formikProps.errors.clientName}
                                        label="Client name"
                                        variant="standard"
                                        {...formikProps.getFieldProps('clientName')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        label="Country Name"
                                        error={Boolean(formikProps.touched.countryId && formikProps.errors.countryId)}
                                        fullWidth
                                        {...formikProps.getFieldProps('countryId')}
                                        helperText={formikProps.touched.countryId && formikProps.errors.countryId}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Country"}
                                        </option>
                                        {countries.map((country: any) => (
                                            <option key={country._id} value={country._id}>
                                                {country.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.clientContactName && formikProps.errors.clientContactName)}
                                        fullWidth
                                        {...formikProps.getFieldProps('clientContactName')}
                                        helperText={formikProps.touched.clientContactName && formikProps.errors.clientContactName}
                                        label="Contact Person"
                                        name="clientContactName"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.clientPhoneNumber && formikProps.errors.clientPhoneNumber)}
                                        fullWidth
                                        {...formikProps.getFieldProps('clientPhoneNumber')}
                                        helperText={formikProps.touched.clientPhoneNumber && formikProps.errors.clientPhoneNumber}
                                        label="Phone Number"
                                        name="clientPhoneNumber"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.clientEmail && formikProps.errors.clientEmail)}
                                        fullWidth
                                        {...formikProps.getFieldProps('clientEmail')}
                                        helperText={formikProps.touched.clientEmail && formikProps.errors.clientEmail}
                                        label="Client Email Address"
                                        name="clientEmail"
                                        type="email"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.clientWebsite && formikProps.errors.clientWebsite)}
                                        fullWidth
                                        {...formikProps.getFieldProps('clientWebsite')}
                                        helperText={formikProps.touched.clientWebsite && formikProps.errors.clientWebsite}
                                        label="Client Website Url"
                                        name="clientWebsite"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.company && formikProps.errors.company)}
                                        fullWidth
                                        {...formikProps.getFieldProps('company')}
                                        helperText={formikProps.touched.company && formikProps.errors.company}
                                        label="Company"
                                        name="company"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        error={Boolean(formikProps.touched.address1 && formikProps.errors.address1)}
                                        fullWidth
                                        {...formikProps.getFieldProps('address1')}
                                        helperText={formikProps.touched.address1 && formikProps.errors.address1}
                                        label="Address Line 1"
                                        name="address1"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        error={Boolean(formikProps.touched.address2 && formikProps.errors.address2)}
                                        fullWidth
                                        {...formikProps.getFieldProps('address2')}
                                        helperText={formikProps.touched.address2 && formikProps.errors.address2}
                                        label="Address Line 2"
                                        name="address2"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.city && formikProps.errors.city)}
                                        fullWidth
                                        {...formikProps.getFieldProps('city')}
                                        helperText={formikProps.touched.city && formikProps.errors.city}
                                        label="City"
                                        name="city"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.state && formikProps.errors.state)}
                                        fullWidth
                                        {...formikProps.getFieldProps('state')}
                                        helperText={formikProps.touched.state && formikProps.errors.state}
                                        label="State"
                                        name="state"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.zipCode && formikProps.errors.zipCode)}
                                        fullWidth
                                        {...formikProps.getFieldProps('zipCode')}
                                        helperText={formikProps.touched.zipCode && formikProps.errors.zipCode}
                                        label="Zip Code"
                                        name="zipCode"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>


                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        error={Boolean(formikProps.touched.netDays && formikProps.errors.netDays)}
                                        fullWidth
                                        {...formikProps.getFieldProps('netDays')}
                                        helperText={formikProps.touched.netDays && formikProps.errors.netDays}
                                        label="Net Days"
                                        name="netDays"
                                        type="text"
                                        variant="standard"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                        <option key={30} value={30}>
                                            30
                                        </option>
                                        <option key={45} value={45}>
                                            45
                                        </option>
                                        <option key={60} value={60}>
                                            60
                                        </option>
                                        <option key={90} value={90}>
                                            90
                                        </option>
                                    </TextField>
                                </Grid>
                                {/* <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        error={Boolean(formikProps.touched.wolfAccount && formikProps.errors.wolfAccount)}
                                        fullWidth
                                        {...formikProps.getFieldProps('wolfAccount')}
                                        helperText={formikProps.touched.wolfAccount && formikProps.errors.wolfAccount}
                                        label="Wolf Account"
                                        name="wolfAccount"
                                        type="text"
                                        variant="standard"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                        <option value="wcanada">
                                            Wolf Canada
                                        </option>
                                        <option value="windia">
                                            Wolf India
                                        </option>

                                    </TextField>
                                </Grid> */}
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.clientApiKey && formikProps.errors.clientApiKey)}
                                        fullWidth
                                        {...formikProps.getFieldProps('clientApiKey')}
                                        helperText={formikProps.touched.clientApiKey && formikProps.errors.clientApiKey}
                                        label="CLient Api Key"
                                        name="clientApiKey"
                                        type="text"
                                        disabled
                                        variant="standard"
                                    />
                                </Grid>


                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Setting</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <List>
                                        <ListItem dense>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                checked={formikProps.values.isApiIntegration}
                                                {...formikProps.getFieldProps('isApiIntegration')}
                                            />
                                            <ListItemText primary={`Api Integration`} />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <List>
                                        <ListItem dense>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                checked={formikProps.values.isScreenUrlIntegration}
                                                {...formikProps.getFieldProps('isScreenUrlIntegration')}
                                            />
                                            <ListItemText primary={`Screener Url Integration`} />
                                        </ListItem>
                                    </List>
                                </Grid>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Integration</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiKey && formikProps.errors.apiKey)}
                                        fullWidth
                                        {...formikProps.getFieldProps('apiKey')}
                                        helperText={formikProps.touched.apiKey && formikProps.errors.apiKey}
                                        label="Api Key"
                                        name="apiKey"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiUrl && formikProps.errors.apiUrl)}
                                        fullWidth
                                        {...formikProps.getFieldProps('apiUrl')}
                                        helperText={formikProps.touched.apiUrl && formikProps.errors.apiUrl}
                                        label="Api Url"
                                        name="apiUrl"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiHeader && formikProps.errors.apiHeader)}
                                        fullWidth
                                        {...formikProps.getFieldProps('apiHeader')}
                                        helperText={formikProps.touched.apiHeader && formikProps.errors.apiHeader}
                                        label="Api Header"
                                        name="apiHeader"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiBody && formikProps.errors.apiBody)}
                                        fullWidth
                                        {...formikProps.getFieldProps('apiBody')}
                                        helperText={formikProps.touched.apiBody && formikProps.errors.apiBody}
                                        label="Api Body"
                                        name="apiBody"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiParams && formikProps.errors.apiParams)}
                                        fullWidth
                                        {...formikProps.getFieldProps('apiParams')}
                                        helperText={formikProps.touched.apiParams && formikProps.errors.apiParams}
                                        label="Api Params"
                                        name="apiParams"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiFunction && formikProps.errors.apiFunction)}
                                        fullWidth
                                        {...formikProps.getFieldProps('apiFunction')}
                                        helperText={formikProps.touched.apiFunction && formikProps.errors.apiFunction}
                                        label="Api Function"
                                        name="apiFunction"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiFunctionHeader && formikProps.errors.apiFunctionHeader)}
                                        fullWidth
                                        {...formikProps.getFieldProps('apiFunctionHeader')}
                                        helperText={formikProps.touched.apiFunctionHeader && formikProps.errors.apiFunctionHeader}
                                        label="Api Function Header"
                                        name="apiFunctionHeader"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.apiResponseVar && formikProps.errors.apiResponseVar)}
                                        fullWidth
                                        {...formikProps.getFieldProps('apiResponseVar')}
                                        helperText={formikProps.touched.apiResponseVar && formikProps.errors.apiResponseVar}
                                        label="Api Response Variable"
                                        name="apiResponseVar"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Variable Mapping</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        minRows={10}
                                        error={Boolean(formikProps.touched.variableMapping && formikProps.errors.variableMapping)}
                                        fullWidth
                                        {...formikProps.getFieldProps('variableMapping')}
                                        helperText={formikProps.touched.variableMapping && formikProps.errors.variableMapping}
                                        label="Variable Mapping"
                                        name="variableMapping"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        minRows={10}
                                        error={Boolean(formikProps.touched.identifierMapping && formikProps.errors.identifierMapping)}
                                        fullWidth
                                        {...formikProps.getFieldProps('identifierMapping')}
                                        helperText={formikProps.touched.identifierMapping && formikProps.errors.identifierMapping}
                                        label="Identifier Mapping"
                                        name="identifierMapping"
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Supplier Mapping</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Supplier</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={formikProps.values.supplierId}
                                            onChange={(e) =>handleChange(e,formikProps) }
                                            input={<Input placeholder="Suppliers" />}
                                            renderValue={(selected) => {
                                                return suppliers.filter((x: any) => { if (selected.includes(x._id)) return x }).map((x: Supplier) => x.supplierName).join(' ,');
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value="all" >
                                                <>
                                                    <Checkbox  checked={suppliers.length > 0 && formikProps.values.supplierId.length === suppliers.length}/>
                                                    <ListItemText  secondary="Select All" />
                                                </>
                                            </MenuItem>
                                            {suppliers.map((supplier: any) => (
                                                <MenuItem key={supplier._id} value={supplier._id}>
                                                    <>
                                                        <Checkbox
                                                            checked={
                                                                formikProps.values && formikProps.values.supplierId.findIndex((rid: string) => rid === supplier._id) >= 0
                                                            }
                                                        />
                                                        <ListItemText primary={supplier.supplierName} />
                                                    </>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Assigned To</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        error={Boolean(formikProps.touched.assignedTo && formikProps.errors.assignedTo)}
                                        fullWidth
                                        {...formikProps.getFieldProps('assignedTo')}
                                        helperText={formikProps.touched.assignedTo && formikProps.errors.assignedTo}
                                        label="BDE"
                                        name="assignedTo"
                                        type="text"
                                        variant="standard"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                        <option key="" value="">
                                            {"Select User"}
                                        </option>
                                        {users.map((user: any) => (
                                            <option key={user._id} value={user._id}>
                                                {user.label}
                                            </option>
                                        ))}

                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {initialValues ? 'Save Client' : 'Create Client'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}

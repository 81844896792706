import { useContext } from 'react';
import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';
import { SupplierRuleForm } from 'src/components/admin/_dashboard/service/SupplierRule';
import { SupplierRule } from 'src/types/supplierRuleTypes';
import { SupplierRuleSingleRequest, SupplierRuleUpdateRequest } from 'src/actions/supplierRuleActions';

export default function EditSupplierRule() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { ruleid }: any = useParams<any>();
    const submitForm = async (values: SupplierRule, setSubmitting: any) => {
        setTimeout(() => dispatch(SupplierRuleUpdateRequest(ruleid, values, setMessageHandler, setSubmitting)), 200);
    }
    const { supplierRule } = useSelector(state => state.supplierRule);
    const initialValues: any = supplierRule

    useEffect(() => {
        dispatch(SupplierRuleSingleRequest(ruleid));
    }, [ruleid])

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Edit Supplier Rule" link="/admin/library/rule"/>
            <SupplierRuleForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}

import { styled } from '@mui/material/styles';
import { Badge, Card, Grid, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { Project } from 'src/types/projectTypes';
import ChildProjectInfoForm from './ChildProjectInfoForm';
import ChildProjectSecurityForm from './ChildProjectSecurityForm';
import ChildProjectUrlForm from './ChildProjectUrlForm';
import ChildProjectSurveyForm from './ChildProjectSurveyForm';
import ChildProjectSupplierForm from './ChildProjectSupplierForm';
import { Icon } from '@iconify/react';
import ChildProjectExcludeForm from './ChildProjectExcludeForm';
import ChildProjectQuestionaireForm from './ChildProjectQuestionaireForm';
import { CitiesAllRequest, RegionAllRequest } from 'src/actions/countryActions';
import { useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import ChildProjectQuotaForm from './ChildProjectQuotaForm';


const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

const TabStyle = styled(Tab)(({ }) => ({
    padding: 0,
    marginRight: 40,
    minHeight: 48
}));


export interface FormValues {
    submitForm: any,
    initialValues: Project
}

export default function ChildProjectUpdateForm({ submitForm, initialValues }: FormValues) {

    const [listItem, setListItem] = useState<number>(0);
    const [countryId, setCountryId] = useState<string>(initialValues.countryId || '');
    const [regionId, setRegionId] = useState<any>(initialValues?.regionId || []);
    const [cityId, setCityId] = useState<any>(initialValues?.cityId || []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setListItem(newValue);
    };

    const dispatch = useDispatch()

    const changeCountry = (value: string, formikProps: FormikProps<Project>) => {
        formikProps.setFieldValue('countryId', value)
        setCountryId(value);
        if (value) dispatch(RegionAllRequest({ countryId: value }));
        dispatch(CitiesAllRequest({ regionId }));
        initialValues.regionId = [];
        initialValues.cityId = [];
        setCityId([])
        setRegionId([])
    }

    const changeRegion = (value: string, formikProps: FormikProps<Project>) => {
        formikProps.setFieldValue('regionId', value)
        setRegionId(value);
        dispatch(CitiesAllRequest({ regionId: value }));
    }

    useEffect(() => {
        if (initialValues.countryId) dispatch(RegionAllRequest({ countryId: initialValues.countryId }));
    }, [initialValues.countryId])


    useEffect(() => {
        if (initialValues.regionId) dispatch(CitiesAllRequest({ regionId: initialValues.regionId }));
    }, [initialValues.regionId])

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                md={12}
            >
                <Tabs
                    value={listItem}
                    onChange={handleChange}
                    aria-label="icon position tabs example"
                >
                    <TabStyle icon={<Icon icon="ant-design:project-outlined" width={20} />} iconPosition="start" label="Project Infomation" />
                    <TabStyle icon={<Icon icon="ant-design:project-outlined" width={20} />} iconPosition="start" label="Project Quota" />
                    <TabStyle icon={<Icon icon="akar-icons:link-on" width={20} />} iconPosition="start" label="Project URLs" />
                    <TabStyle icon={<Icon icon="ic:baseline-security" width={20} />} iconPosition="start" label="Project Securities" />
                    <TabStyle icon={<Badge>{initialValues.questionCount}</Badge>} iconPosition="start" label="Pre Questionaire" />
                    <TabStyle icon={<Badge>{initialValues.supplierCount}</Badge>} iconPosition="start" label="Suppliers" />
                    <TabStyle icon={<Icon icon="ic:baseline-quiz" width={20} />} iconPosition="start" label="Exclude/Include" />
                </Tabs>
            </Grid>
            <Grid
                item
                md={12}
            >
                {listItem == 0 && <CardStyle><Grid
                    item
                    md={12}
                >{initialValues.parentProjectId && <ChildProjectInfoForm newProject={false} changeRegion={changeRegion} cityId={cityId} regionId={regionId} changeCountry={changeCountry} countryId={countryId} initialValues={initialValues} submitForm={submitForm} />}</Grid> </CardStyle>}

                {listItem == 1 && <Grid
                    item
                    md={12}
                ><ChildProjectQuotaForm initialValues={initialValues} submitForm={submitForm} /></Grid>}
                {listItem == 2 && <CardStyle><Grid
                    item
                    md={12}
                ><ChildProjectUrlForm initialValues={initialValues} submitForm={submitForm} /></Grid></CardStyle>}
                {listItem == 3 && <CardStyle><Grid
                    item
                    md={12}
                ><ChildProjectSecurityForm initialValues={initialValues} submitForm={submitForm} /></Grid></CardStyle>}
                {listItem == 4 && <CardStyle><Grid
                    item
                    md={12}
                ><ChildProjectSurveyForm initialValues={initialValues} submitForm={submitForm} /></Grid></CardStyle>}
                {listItem == 5 && <Grid
                    item
                    md={12}
                >
                    <ChildProjectSupplierForm />
                    <ChildProjectQuestionaireForm initialValues={initialValues} submitForm={submitForm} />
                </Grid>}
                {listItem == 6 && <Grid
                    item
                    md={12}
                ><ChildProjectExcludeForm /></Grid>}
            </Grid>
        </Grid>
    );
}

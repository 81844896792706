import { useEffect } from 'react';
import { useState } from 'react';
import { Container, IconButton, Typography, Tooltip, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { DataGridRender } from 'src/components/table';
import { ProjectReportsRequest, ProjectResetSuccess, ProjectGroupExportRequest, ProjectPrescreenReportsRequest, SupplierDataExportRequest, IdentifierExportRequest, ProjectIdentifierExportRequest } from 'src/actions/projectActions';
import { Download } from '@mui/icons-material';
import TabLayout from 'src/components/tab/TabLayout';
import TopBar from 'src/components/shared/TopBar';
import { projectActionTypes } from 'src/types/actionTypes';

export default function ProjectReport() {

  const [tabValue, setTabValue] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [loading,setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<any>({});
  const [filterTypes, setFilterTypes] = useState<any>(['dateRange', 'export', 'client', 'download'])
  const dispatch = useDispatch();
  const {  project, reportProjects } = useSelector(state => state.project);

  useEffect(() => {
    if (tabValue == 5) {
      dispatch(ProjectReportsRequest(filters, { status: 'c' }));
    }
    else if (Object.keys(filters).length) {
      dispatch(ProjectReportsRequest(filters, {}));
    }

    else {
      dispatch(ProjectResetSuccess({}));
    }
  }, [filters, tabValue]);


  const exportAction = (id: string, projectName: string) => {
    if (tabValue == 1) dispatch(ProjectGroupExportRequest({ projectId: id }, projectName));
    if (tabValue == 2) dispatch(ProjectGroupExportRequest({ projectId: id }, projectName));
    if (tabValue == 3) dispatch(ProjectPrescreenReportsRequest({ projectId: id }, projectName));
  }

  const exportSupplierAction = (supplierName: string) => {
    dispatch(SupplierDataExportRequest(filters, supplierName));
  }

  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 150, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Typography color="primary" >{value}</Typography>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    { field: 'complete', type: "string", headerName: 'Complete', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    { field: 'terminate', type: "string", headerName: 'Terminate', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    { field: 'qualityTerm', type: "string", headerName: 'Quality Terminate', filterable: false, headerAlign: "center", align: 'center', width: 150 },
    { field: 'overQuota', type: "string", headerName: 'Over Quota', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    { field: 'clicked', type: "string", headerName: 'Traffic', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    { field: 'totalDisqualified', type: "string", headerName: 'Disqualified', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    { field: 'drop', type: "string", headerName: 'Dropped', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    {
      field: 'isActive', headerName: '', headerAlign: "center", align: 'center', disableExport: true, type: "boolean", width: 80, renderCell: ({ row }: any) => {
        return <IconButton onClick={() => exportAction(row._id, row['projectCode'] + "_" + row['projectName'])}>
          <Download />
        </IconButton>
      }
    },
  ];

  const columnsComplete = [
    { field: 'complete', type: "string", headerName: 'Total Completes', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    { field: 'approved', type: "string", headerName: 'Total Approved', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    {
      field: 'isActive', headerName: 'Action', headerAlign: "center", align: 'center', disableExport: true, type: "boolean", width: 200, renderCell: ({ row }: any) => {
        return <IconButton onClick={() => exportSupplierAction(row['supplierName'])}>
          <Download />
        </IconButton>
      }
    },
  ];

  const handleChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
    setFilters({});
    dispatch({ type: projectActionTypes.FETCH_REPORT_PROJECT_SUCCESS, payload: { reportProjects: [], loading: false } });
    if (value == 1) setFilterTypes(['dateRange', 'export', 'client']);
    if (value == 2) setFilterTypes(['dateRange', 'export', 'supplier']);
    if (value == 3) setFilterTypes(['dateRange', 'export', 'project']);
    if (value == 4) setFilterTypes(['dateRange', 'export']);
    if (value == 5) setFilterTypes(['dateRange', 'export', 'supplier']);
    if (value == 6) setFilterTypes(['searcharea', 'supplier', 'exportIdentifier']);
  }

  const exportIdentfier = () => {
     dispatch(ProjectIdentifierExportRequest({projectData:filters.search,supplier:filters.supplier },setLoading));
  }
  return (
    <Container maxWidth={false}>
      <TopBar title="Reports" link="" buttonText="" />
      <TabLayout handleChange={handleChange} tabValue={tabValue}>
        <Tab label="Client" value={1} />
        <Tab label="Supplier" value={2} />
        <Tab label="Prescreen" value={3} />
        <Tab label="Complete Allocated" value={4} />
        <Tab label="Complete" value={5} />
        <Tab label="Project Complete" value={6} />
      </TabLayout>
      {tabValue == 6 ?
        <DataGridRender
          filterTypes={filterTypes}
          data={reportProjects}
          metadata=""
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          exportFileName='data'
          columns={columns}
          setPageNo={setPageNo}
          exportIdentifierLoading={loading}
          exportIdentfier={exportIdentfier}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        /> :
        <DataGridRender
          filterTypes={filterTypes}
          data={reportProjects}
          metadata=""
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          exportFileName={project.projectCode + "_" + project.projectName}
          columns={tabValue == 5 ? columnsComplete : columns}
          setPageNo={setPageNo}
          exportIdentfier={exportIdentfier}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />}
    </Container>
  );
}

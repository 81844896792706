import { TextField, Grid, List, ListItem, Checkbox, ListItemText, FormGroup, FormControlLabel, Typography, Divider } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Project } from 'src/types/projectTypes';

export interface FormValues {
    initialValues: Project,
    submitForm: any,
}

export default function ChildProjectSecurityForm({ initialValues, submitForm }: FormValues) {

    return (
        <Formik
            onSubmit={(values, { setSubmitting }) => {
                submitForm(values, setSubmitting);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={true}
            initialValues={{
                isMultiLinkUrl: initialValues?.isMultiLinkUrl,
                isDynamicThanksUrl: initialValues?.isDynamicThanksUrl,
                isGeoLocation: initialValues?.isGeoLocation,
                isClientUrlProtection: initialValues?.isClientUrlProtection,
                isPreScreen: initialValues?.isPreScreen,
                isExclude: initialValues?.isExclude,
                isMobile: initialValues?.isMobile,
                isDesktop: initialValues?.isDesktop,
                isTablet: initialValues?.isTablet,
                isSpeeder: initialValues?.isSpeeder,
                isProxyVpn: initialValues?.isProxyVpn,
                isUniqueIp: initialValues?.isUniqueIp,
                isPii: initialValues?.isPii,
                isRDLow: initialValues?.isRDLow,
                isRDMedium: initialValues?.isRDMedium,
                isRDHigh: initialValues?.isRDHigh,
                maxIPCount: initialValues?.maxIPCount,
                speedTime: initialValues?.speedTime,
            }}
        >
            {(formikProps: FormikProps<Project>) => (
                <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Typography variant="h6">Geolocation & Device Fingerprinting</Typography>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormGroup row >
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isGeoLocation}
                                            onChange={(event) => formikProps.setFieldValue('isGeoLocation', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">Geo Location</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isMobile}
                                            onChange={(event) => formikProps.setFieldValue('isMobile', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">Mobile Study</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isTablet}
                                            onChange={(event) => formikProps.setFieldValue('isTablet', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">Tablet Study</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isDesktop}
                                            onChange={(event) => formikProps.setFieldValue('isDesktop', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">Desktop Study</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isProxyVpn}
                                            onChange={(event) => formikProps.setFieldValue('isProxyVpn', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">Proxy/VPN</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isUniqueIp}
                                            onChange={(event) => formikProps.setFieldValue('isUniqueIp', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">Unique IP</Typography>}
                                />
                                {formikProps.values.isUniqueIp && <TextField
                                    sx={{ mr: 4 }}
                                    style={{ width: 60 }}
                                    variant="standard"
                                    error={Boolean(formikProps.touched.maxIPCount && formikProps.errors.maxIPCount)}
                                    label=""
                                    checked={formikProps.values.maxIPCount}
                                    {...formikProps.getFieldProps('maxIPCount')}
                                />}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isPii}
                                            onChange={(event) => formikProps.setFieldValue('isPii', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">PII</Typography>}
                                />
                            </FormGroup>
                        </Grid>



                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Divider />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Typography variant="h6">Threat Protection</Typography>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormGroup row >
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isRDLow}
                                            onChange={(event) => formikProps.setFieldValue('isRDLow', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">RD Low</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isRDMedium}
                                            onChange={(event) => formikProps.setFieldValue('isRDMedium', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">RD Medium</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isRDHigh}
                                            onChange={(event) => formikProps.setFieldValue('isRDHigh', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">RD High</Typography>}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Divider />
                        </Grid>

                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Typography variant="h6">Survey Protection</Typography>
                        </Grid>
                        <Grid
                            item
                            md={5}
                            xs={12}
                        >
                            <FormGroup row >
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isExclude}
                                            onChange={(event) => formikProps.setFieldValue('isExclude', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">Exclude</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isClientUrlProtection}
                                            onChange={(event) => formikProps.setFieldValue('isClientUrlProtection', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">Url Protection</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ mr: 4 }}
                                    control={
                                        <Checkbox
                                            checked={formikProps.values.isSpeeder}
                                            onChange={(event) => formikProps.setFieldValue('isSpeeder', event.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2">Speeder</Typography>}
                                />
                                {formikProps.values.isSpeeder && <TextField
                                    sx={{ mr: 4 }}
                                    style={{ width: 60 }}
                                    variant="standard"
                                    error={Boolean(formikProps.touched.speedTime && formikProps.errors.speedTime)}
                                    label=""
                                    checked={formikProps.values.speedTime}
                                    {...formikProps.getFieldProps('speedTime')}
                                />}
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid marginTop={2} item xs={2}>
                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            loading={formikProps.isSubmitting}
                        >
                            {initialValues ? 'Save Project' : 'Create Project'}
                        </LoadingButton>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

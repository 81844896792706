import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, DialogProps, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
interface ResponsiveDialogProps {
  title: string;
  subTitle:string;
  customWidth?:number;
  setOpenDialog: any;
  openDialog: boolean;
  children: React.ReactNode;
}

const ResponsiveDialog = ({ title,customWidth,subTitle, openDialog, setOpenDialog, children }: ResponsiveDialogProps) => {

  const [maxWidth] = React.useState<DialogProps['maxWidth']>('lg');

  return (
    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      onClose={() => setOpenDialog('')}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">
          {title}
        </Typography>
        <Typography variant="h5">
          {subTitle}
        </Typography>
        <IconButton onClick={() => setOpenDialog('')}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{width:customWidth}}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default ResponsiveDialog;
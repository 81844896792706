import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid, Typography, Divider } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useEffect, useRef } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { ClientAllRequestData } from 'src/actions/clientActions';
import { useDispatch } from 'react-redux';
import { Project } from 'src/types/projectTypes';
import { Client } from 'src/types/clientTypes';
import { ProjectStatusMain } from 'src/constants';
//import { Editor } from '@tinymce/tinymce-react';
import Editor from 'react-simple-wysiwyg';
import { UserAllRequestDataAdmin } from 'src/actions/userActions';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface FormValues {
    initialValues: Project,
    submitForm: any,
}

export default function ProjectForm({ initialValues, submitForm }: FormValues) {

    const editorRef: any = useRef(null);
    const SchemaCategoryForm = Yup.object().shape({
        clientId: Yup.string().trim().required('Client is required'),
        userId: Yup.string().trim().required('Project Manager is required'),
        projectName: Yup.string().trim().min(4).required('Project name  is required'),
        projectStatus: Yup.number().required('Project Status  is required'),
        averageLOI: Yup.string().trim().matches(/^[0-9\s]+$/, "Only digits are allowed for this field ").required('LOI  is required'),
        ir: Yup.string().trim().matches(/^[0-9\s]+$/, "Only digits are allowed for this field ").required('IR  is required'),
        poNumber: Yup.string().when("projectStatus", {
            is: (aCheckbox: number) => aCheckbox === 6,
            then: Yup.string().required('PO Number is required')
        })
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { admin } = useSelector(state => state.admin);
    const { adminInfo } = admin;
    const { users } = useSelector(state => state.user);
    const { redirectTo } = useSelector(state => state.project);
    const { clients } = useSelector(state => state.client);


    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])

    useEffect(() => {
        dispatch(ClientAllRequestData(''))
        dispatch(UserAllRequestDataAdmin())
    }, [])


    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    projectType: 1,
                    clientId: initialValues?.clientId,
                    projectSpecs: initialValues?.projectSpecs || '',
                    projectName: initialValues?.projectName || '',
                    userId: initialValues?.userId || adminInfo?._id,
                    projectDescription: initialValues?.projectDescription || '',
                    notes: initialValues?.notes || '',
                    isDynamicThanksUrl: initialValues?.isDynamicThanksUrl || true,
                    projectStatus: initialValues?.projectStatus || 2,
                    poNumber: initialValues?.poNumber || '',
                    averageLOI: initialValues?.averageLOI || '',
                    clientEmail: initialValues?.clientEmail || '',
                    ir: initialValues?.ir || '',
                }}
            >
                {(formikProps: FormikProps<Project>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Typography variant="h6">Project Details</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.projectName && formikProps.errors.projectName)}
                                        fullWidth
                                        helperText={formikProps.touched.projectName && formikProps.errors.projectName}
                                        label="Project name"
                                        {...formikProps.getFieldProps('projectName')}
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.projectDescription && formikProps.errors.projectDescription)}
                                        fullWidth
                                        helperText={formikProps.touched.projectDescription && formikProps.errors.projectDescription}
                                        label="Project Description"
                                        {...formikProps.getFieldProps('projectDescription')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.notes && formikProps.errors.notes)}
                                        fullWidth
                                        minRows={4}
                                        maxRows={10}
                                        helperText={formikProps.touched.notes && formikProps.errors.notes}
                                        label="Notes"
                                        {...formikProps.getFieldProps('notes')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        label="Client Name"
                                        error={Boolean(formikProps.touched.clientId && formikProps.errors.clientId)}
                                        fullWidth
                                        helperText={formikProps.touched.clientId && formikProps.errors.clientId}
                                        {...formikProps.getFieldProps('clientId')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Client"}
                                        </option>
                                        {clients.map((client: Client) => (
                                            <option key={client.value} value={client.value}>
                                                {client.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(formikProps.touched.isDynamicThanksUrl && formikProps.errors.isDynamicThanksUrl)}
                                        fullWidth
                                        minRows={4}
                                        maxRows={10}
                                        helperText={formikProps.touched.isDynamicThanksUrl && formikProps.errors.isDynamicThanksUrl}
                                        label="Secured Url"
                                        {...formikProps.getFieldProps('isDynamicThanksUrl')}
                                        type="text"
                                        variant="standard"
                                    >
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        label="Project Status"
                                        error={Boolean(formikProps.touched.projectStatus && formikProps.errors.projectStatus)}
                                        fullWidth
                                        helperText={formikProps.touched.projectStatus && formikProps.errors.projectStatus}
                                        {...formikProps.getFieldProps('projectStatus')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        {ProjectStatusMain.map((projectStat: any) => (
                                            <option key={projectStat.value} value={projectStat.value}>
                                                {projectStat.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                {formikProps.values.projectStatus == 6 && <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        label="PO Number"
                                        error={Boolean(formikProps.touched.poNumber && formikProps.errors.poNumber)}
                                        fullWidth
                                        helperText={formikProps.touched.poNumber && formikProps.errors.poNumber}
                                        {...formikProps.getFieldProps('poNumber')}
                                        variant="standard"
                                    />
                                </Grid>}
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        label="Project Manager"
                                        disabled={adminInfo?.role == "MANAGER"}
                                        error={Boolean(formikProps.touched.userId && formikProps.errors.userId)}
                                        fullWidth
                                        helperText={formikProps.touched.userId && formikProps.errors.userId}
                                        {...formikProps.getFieldProps('userId')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Manager"}
                                        </option>
                                        {users.map((user: any) => (
                                            <option key={user._id} value={user._id}>
                                                {user.firstName + " " + user.lastName}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        label="Project LOI"
                                        error={Boolean(formikProps.touched.averageLOI && formikProps.errors.averageLOI)}
                                        fullWidth
                                        helperText={formikProps.touched.averageLOI && formikProps.errors.averageLOI}
                                        {...formikProps.getFieldProps('averageLOI')}
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        label="Project IR"
                                        error={Boolean(formikProps.touched.ir && formikProps.errors.ir)}
                                        fullWidth
                                        helperText={formikProps.touched.ir && formikProps.errors.ir}
                                        {...formikProps.getFieldProps('ir')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Typography variant="h6">Client Details</Typography>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    label="Client Email"
                                    error={Boolean(formikProps.touched.clientEmail && formikProps.errors.clientEmail)}
                                    fullWidth
                                    helperText={formikProps.touched.clientEmail && formikProps.errors.clientEmail}
                                    {...formikProps.getFieldProps('clientEmail')}
                                    variant="standard"
                                />
                            </Grid>

                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Typography variant="h6">Project Specifications</Typography>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                
                                {/* <Editor
                                    apiKey='hjckgihh198u8grz87gzmg8otea07eg8wb0henzchkpt5ag2'
                                    initialValue={initialValues?.projectSpecs || ''}
                                    onEditorChange={(newText) => formikProps.setFieldValue('projectSpecs', newText)}
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                /> */}
                                                                <Editor
                                containerProps={{ style: { resize: 'vertical' } }}
                                value={formikProps.values.projectSpecs || initialValues?.projectSpecs} onChange={(e) => formikProps.setFieldValue('projectSpecs', e.target.value)}/>
                            </Grid>
                            </Grid>


                            <Grid item xs={2}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {initialValues ? 'Save Project' : 'Create Project'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}

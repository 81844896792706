import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
import NavSection from 'src/components/NavSection';
import AccountPopover from './AccountPopover';
import Logo from "src/components/Logo";
import { Link } from "react-router-dom";
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 70;

const RootStyle = styled(AppBar)(({ theme }) => ({
  overflowY: "scroll",
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: theme.palette.primary.main,
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));


export const DashboardNavbar = (props: any) => {
  const { adminUser, adminRoutes } = props;
  return (
    <RootStyle>
      <ToolbarStyle>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }} >
          <Link to="/admin/dashboard">
            <Logo />
          </Link>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }} >
          <NavSection adminUser={adminUser} navConfig={adminRoutes} />
        </Box>
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

export default DashboardNavbar;

import { useNavigate } from 'react-router-dom';
import { Container, Tab } from '@mui/material';
import TabLayout from 'src/components/tab/TabLayout';
import TopBar from 'src/components/shared/TopBar';
import QuestionSurveyForm from 'src/components/admin/_dashboard/library/QuestionSurveyForm';

export default function QuestionList() {

  const navigate = useNavigate();
  const initialValues: any = {};
  const submitForm = () => { }

  return (
    <Container maxWidth={false}>
      <TopBar title="Library" link="" buttonText="" />
      <TabLayout handleChange="" tabValue={2}>
        <Tab label="Categories" onClick={() => navigate("/admin/library")} value={1} />
        <Tab label="Questions" onClick={() => navigate("/admin/library/question")} value={2} />
        <Tab label="Supplier Rules" onClick={() => navigate("/admin/library/rule")} value={3} />
        <Tab label="Qualfications" onClick={() => navigate("/admin/library/qualification")} value={4} />
      </TabLayout>
      <QuestionSurveyForm initialValues={initialValues} submitForm={submitForm} />
    </Container>
  );
}

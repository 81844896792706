import axios from 'axios';

const AXIOS = axios.create({
  baseURL:process.env.NODE_ENV == "development"? "http://localhost:3092":"https://project.opinion-nation.com",
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 20000,
});

export default AXIOS;
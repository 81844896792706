import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { InvoiceStatus } from 'src/constants';
import { Invoice } from 'src/types/invoiceTypes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'src/reducers';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface FormValues {
    initialValues: Invoice,
    submitForm: any,
}

export default function InvoiceStatusForm({ initialValues, submitForm }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        referenceNo: Yup.string().required('Reference is required'),
        recievedDate: Yup.string().required('Recieved Date  is required'),
    });

    const navigate = useNavigate();

    const { redirectTo } = useSelector(state => state.invoice);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])
    
    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    projects:initialValues?.projects || [],
                    referenceNo: initialValues?.referenceNo || '',
                    recievedDate: initialValues?.recievedDate || '',
                    comment: initialValues?.comment || '',
                    dueDate:initialValues?.dueDate ,
                    invoiceStatus: initialValues?.invoiceStatus,
                }}
            >
                {(formikProps: FormikProps<Invoice>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.referenceNo && formikProps.errors.referenceNo)}
                                        fullWidth
                                        helperText={formikProps.touched.referenceNo && formikProps.errors.referenceNo}
                                        label="Reference Number"
                                        {...formikProps.getFieldProps('referenceNo')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.recievedDate && formikProps.errors.recievedDate)}
                                        fullWidth
                                        helperText={formikProps.touched.recievedDate && formikProps.errors.recievedDate}
                                        label="Recieved Date"
                                        {...formikProps.getFieldProps('recievedDate')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.comment && formikProps.errors.comment)}
                                        fullWidth
                                        minRows={4}
                                        maxRows={10}
                                        helperText={formikProps.touched.comment && formikProps.errors.comment}
                                        label="Comment"
                                        {...formikProps.getFieldProps('comment')}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        label="Project Status"
                                        error={Boolean(formikProps.touched.invoiceStatus && formikProps.errors.invoiceStatus)}
                                        fullWidth
                                        helperText={formikProps.touched.invoiceStatus && formikProps.errors.invoiceStatus}
                                        {...formikProps.getFieldProps('invoiceStatus')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        {InvoiceStatus.map((invoiceStat: any) => (
                                            <option key={invoiceStat.value} value={invoiceStat.value}>
                                                {invoiceStat.type}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {initialValues ? 'Save Invoice' : 'Create Project'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}

import { combineReducers } from 'redux'
import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux'
import categoryReducer from './categoryReducer'
import countryReducer from './countryReducer'
import adminReducer from './adminReducer'
import projectReducer from './projectReducer'
import dashboardReducer from './dashboardReducer'
import clientReducer from './clientReducer'
import supplierReducer from './supplierReducer'
import supplierMapReducer from './supplierMapReducer'
import supplierRuleReducer from './supplierRuleReducer'
import userReducer from './userReducer'
import invoiceReducer from './invoiceReducer'
import surveyReducer from './surveyReducer'
import questionReducer from './questionReducer'
import qualificationReducer from './qualificationReducer'
import quotaReducer from './quotaReducer'
import shortCodeReducer from './shortCodeReducer'
import systemLogReducer from './systemLogReducer'
import scheduleJobReducer from './scheduleJobReducer'
import { adminPayload } from 'src/types/adminTypes'
import { ClientList } from 'src/types/clientTypes'
import { CountryList } from 'src/types/countryTypes'
import { CategoryList } from 'src/types/categoryTypes'
import { SupplierList } from 'src/types/supplierTypes'
import { DashboardPayload } from 'src/types/dashboardTypes'
import { QuestionList, SurveyList } from 'src/types/surveyTypes'
import { ProjectList } from 'src/types/projectTypes'
import { SupplierMapList } from 'src/types/supplierMapTypes'
import { UserList } from 'src/types/userTypes'
import { ShortCodeList } from 'src/types/shortCodeTypes'
import { InvoiceList } from 'src/types/invoiceTypes'
import { SystemLogList } from 'src/types/systemLogTypes'
import { ScheduleJobList } from 'src/types/scheduleJobTypes'
import { SupplierRuleList } from 'src/types/supplierRuleTypes'
import { QualificationList } from 'src/types/qualificationTypes'
import { QuotaList } from 'src/types/quotaTypes'

export type AppState = {
    admin: adminPayload,
    dashboard:DashboardPayload,
    project:ProjectList,
    category: CategoryList,
    country: CountryList,
    client:ClientList,
    supplier:SupplierList,
    survey:SurveyList,
    supplierMap:SupplierMapList,
    user:UserList,
    question:QuestionList,
    shortCode:ShortCodeList,
    systemLog:SystemLogList,
    invoice:InvoiceList,
    scheduleJob:ScheduleJobList,
    supplierRule:SupplierRuleList,
    qualification:QualificationList,
    quota:QuotaList,
}
const rootReducer = combineReducers<AppState>({
    admin:adminReducer,
    category:categoryReducer,
    dashboard:dashboardReducer,
    project:projectReducer,
    country:countryReducer,
    client:clientReducer,
    supplier:supplierReducer,
    survey:surveyReducer,
    supplierMap:supplierMapReducer,
    user:userReducer,
    question:questionReducer,
    shortCode:shortCodeReducer,
    systemLog:systemLogReducer,
    invoice:invoiceReducer,
    scheduleJob:scheduleJobReducer,
    supplierRule:supplierRuleReducer,
    qualification:qualificationReducer,
    quota:quotaReducer,
})

export const useSelector:TypedUseSelectorHook<ReturnType<typeof rootReducer>> = useReduxSelector
export default rootReducer
import PropTypes from 'prop-types';
import {  matchPath, useLocation } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List,  ListItemText, ListItemIcon, ListItemButton, Link } from '@mui/material';

const ListItemStyle = styled((props: any) => <ListItemButton disableGutters {...props} />)(
  ({ theme }: any) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  marginRight: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});


NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }: any) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info } = item;

  const activeRootStyle = {
    color: 'common.white',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };
  return (
    <Link href={path} underline="none">
      <ListItemStyle
        sx={{
          ...(isActiveRoot && activeRootStyle)
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </ListItemStyle>
    </Link>
  );
}


export default function NavSection({ adminUser, navConfig, ...other }: any) {
  const { pathname } = useLocation();
  const match = (path: string) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  return (
    <Box {...other}>
      <List sx={{ display: 'flex', justifyContent: 'center' }} disablePadding>
        {navConfig.map((item: any) => (
          item.roles.includes(adminUser && adminUser.role) && item.show && <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}

import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { ClientAllRequestData } from 'src/actions/clientActions';
import { useDispatch } from 'react-redux';
import { Project } from 'src/types/projectTypes';
import { Client } from 'src/types/clientTypes';
import { ProjectStatus } from 'src/constants';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface FormValues {
    initialValues: Project,
    submitForm: any,
}

export default function InvoiceProjectForm({ initialValues, submitForm }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        clientId: Yup.string().trim().required('Client is required'),
        projectCode: Yup.string().required('Project code  is required'),
        projectName: Yup.string().trim().min(4).required('Project name  is required'),
        projectStatus: Yup.number().required('Project Status  is required'),
        poNumber:Yup.string().when("projectStatus", {
            is: (aCheckbox:number) => aCheckbox === 6,
            then: Yup.string().required('PO Number is required')
        })
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { redirectTo } = useSelector(state => state.project);
    const { clients } = useSelector(state => state.client);


    useEffect(() => {
        if (redirectTo) navigate('/admin/invoice', { replace: true })
    }, [redirectTo])

    useEffect(() => {
        dispatch(ClientAllRequestData(''))
    }, [])

    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    projectType: 1,
                    clientId: initialValues?.clientId,
                    projectCode: initialValues?.projectCode || '',
                    projectName: initialValues?.projectName || '',
                    projectStatus: initialValues?.projectStatus,
                    poNumber: initialValues?.poNumber || '',
                }}
            >
                {(formikProps: FormikProps<Project>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.projectCode && formikProps.errors.projectCode)}
                                        fullWidth
                                        helperText={formikProps.touched.projectCode && formikProps.errors.projectCode}
                                        label="Project code"
                                        {...formikProps.getFieldProps('projectCode')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.projectName && formikProps.errors.projectName)}
                                        fullWidth
                                        helperText={formikProps.touched.projectName && formikProps.errors.projectName}
                                        label="Project name"
                                        {...formikProps.getFieldProps('projectName')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        label="Client Name"
                                        error={Boolean(formikProps.touched.clientId && formikProps.errors.clientId)}
                                        fullWidth
                                        helperText={formikProps.touched.clientId && formikProps.errors.clientId}
                                        {...formikProps.getFieldProps('clientId')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Client"}
                                        </option>
                                        {clients.map((client: Client) => (
                                            <option key={client._id} value={client._id}>
                                                {client.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        select
                                        label="Project Status"
                                        error={Boolean(formikProps.touched.projectStatus && formikProps.errors.projectStatus)}
                                        fullWidth
                                        helperText={formikProps.touched.projectStatus && formikProps.errors.projectStatus}
                                        {...formikProps.getFieldProps('projectStatus')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        {ProjectStatus.map((projectStat: any) => (
                                            <option key={projectStat.value} value={projectStat.value}>
                                                {projectStat.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                               <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        label="PO Number"
                                        error={Boolean(formikProps.touched.poNumber && formikProps.errors.poNumber)}
                                        fullWidth
                                        helperText={formikProps.touched.poNumber && formikProps.errors.poNumber}
                                        {...formikProps.getFieldProps('poNumber')}
                                        variant="standard"
                                    />
                                </Grid>
                           </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {initialValues ? 'Save Project' : 'Create Project'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}

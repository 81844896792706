import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { DashboardBoxDataRequest, DashboardProjectListDataRequest } from 'src/actions';
import { AppNewUsers, AppItemOrders, AppWeeklySales,DashboardProjectSecurityDialog } from 'src/components/admin/_dashboard/app/Dashboard';
import AppBugReports from './AppBugReports';
import { DashboardProjectListSecurityDataRequest } from 'src/actions/dashboardActions';

export default function DashboardBoxSection() {

    const [filters, setFilters] = useState<any>({sortDate:1 });
    const [securityFilters, setSecurityFilters] = useState<any>({sortDate:1 });
    const dispatch = useDispatch();
    const dashboardData = useSelector(state => state.dashboard);
    const { dashboardBoxSectionData, dashboardProjectListData,dashboardProjectListSecurityData } = dashboardData;

    useEffect(() => {
        dispatch(DashboardBoxDataRequest())
    }, [])

    // useEffect(() => {
    //     dispatch(DashboardProjectListDataRequest(filters))
    // }, [filters])


    // useEffect(() => {
    //     dispatch(DashboardProjectListSecurityDataRequest(securityFilters))
    // }, [securityFilters])

    
    return (
        <>
            <Grid item xs={12} sm={3} md={2.4}>
                <AppWeeklySales totalCompleted={dashboardBoxSectionData?.totalCompleted} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.4}>
                <AppNewUsers filters={filters} setFilters={setFilters} todayCompleted={dashboardBoxSectionData?.todayCompleted} dashboardProjectListData={dashboardProjectListData} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.4}>
                <AppItemOrders monthCompleted={dashboardBoxSectionData?.monthCompleted} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.4}>
                <AppBugReports title="Total Projects" value={dashboardBoxSectionData?.totalProjects} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.4}>
                <AppBugReports title="Monthly Revenue" value={dashboardBoxSectionData?.monthlyRevenue} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.4}>
                <AppBugReports title="Monthly Expense" value={dashboardBoxSectionData?.monthlyExpense} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.4}>
                <AppBugReports title="Monthly Invoice" value={dashboardBoxSectionData?.monthlyInvoice} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.4}>
                <DashboardProjectSecurityDialog  securityFilters={securityFilters} setSecurityFilters={setSecurityFilters} todaySecurityDrop={dashboardBoxSectionData?.todaySecurityDrop} dashboardProjectListSecurityData={dashboardProjectListSecurityData} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.4}>
                <AppBugReports title="Monthly Projects Cost" value={dashboardBoxSectionData?.revenueClosedInvoice} />
            </Grid>
            <Grid item xs={12} sm={3} md={2.4}>
                <AppBugReports title="Monthly Invoice Cost" value={dashboardBoxSectionData?.monthlyInvoiceCost} />
            </Grid>

        </>
    );
}

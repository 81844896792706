import { Box, FormControl, Button, FormGroup, IconButton, InputLabel, Select, TextField, MenuItem, Autocomplete } from '@mui/material';
import { GridCsvGetRowsToExportParams, gridVisibleSortedRowIdsSelector, useGridApiContext, GridCsvExportOptions } from '@mui/x-data-grid';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CountryAllRequest } from 'src/actions/countryActions';
import { useSelector } from 'src/reducers';
import { ProjectStatus } from 'src/constants';
import CachedIcon from '@mui/icons-material/Cached';
import { Download } from '@mui/icons-material';
import { ProjectAllRequestData } from 'src/actions/projectActions';
import { Project } from 'src/types/projectTypes';
import { ClientAllRequestData, ClientAllRequestDataWithFilter } from 'src/actions/clientActions';
import { SupplierAllRequestData } from 'src/actions/supplierActions';
import { Client } from 'src/types/clientTypes';
import { Supplier } from 'src/types/supplierTypes';
import { UserAllRequestData } from 'src/actions/userActions';
import { adminInfoProps } from 'src/types/adminTypes';
import { useNavigate } from 'react-router-dom';
import { Country } from 'src/types/countryTypes';
import { LoadingButton } from '@mui/lab';

interface ToolbarProps {
  filters: any;
  state: any;
  setSearch: any;
  filterTypes: any;
  setFilters: any;
  reloadAction: any;
  exportFileName: string;
  multilinesearch: boolean;
  searchwidth: string;
  searchheight: number;
  urlAction: any;
  notifyAction:any;
  exportIdentfier:any,
  exportIdentifierLoading:any
}

const ListToolbar = ({ filters,exportIdentfier,exportIdentifierLoading, filterTypes, notifyAction,urlAction, state, setFilters, reloadAction, exportFileName, multilinesearch, searchwidth, searchheight }: ToolbarProps) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState<string>('');
  const {  countries } = useSelector(state => state.country);
  const { clients } = useSelector(state => state.client);
  const { users } = useSelector(state => state.user);
  const { projects, metadata } = useSelector(state => state.project);
  const { suppliers } = useSelector(state => state.supplier);

  const setFilterItem = (type: string, value: any) => {
    if (!value) {
      delete filters[type];
    }
    else {
      var newobj = { [type]: value };
      setFilters({ ...filters, ...newobj });
    }
  }

  const getFilterItem = (type: string) => {
    return filters[type] || '';
  }



  const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) =>
    gridVisibleSortedRowIdsSelector(apiRef);


  const apiRef = useGridApiContext();

  const handleExport = (options: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options);

  useEffect(() => {

    if (state && searchInput) {
      navigate('/admin/project', { replace: true })
    }
    const timer = setTimeout(() => {
      setFilterItem('search', searchInput.trim());
    }, 500)
    return () => clearTimeout(timer)

  }, [searchInput.trim()])

  useEffect(() => {
    if (state) {
      setSearchInput(state);
      setFilterItem('search', state);
    }
  }, [state])


  useEffect(() => {
    if (filterTypes.includes('countries')) dispatch(CountryAllRequest());
  }, [filterTypes.includes('countries')])


  useEffect(() => {
    if (filterTypes.includes('project')) dispatch(ProjectAllRequestData());
  }, [filterTypes.includes('project')])

  useEffect(() => {
    if (filterTypes.includes('client')) dispatch(ClientAllRequestData(''));
  }, [filterTypes.includes('client')])

  useEffect(() => {
    if (filterTypes.includes('clientdiy')) dispatch(ClientAllRequestDataWithFilter({ isApiIntegration: true }));
  }, [filterTypes.includes('clientdiy')])


  useEffect(() => {
    if (filterTypes.includes('supplier')) dispatch(SupplierAllRequestData());
  }, [filterTypes.includes('supplier')])


  useEffect(() => {
    if (filterTypes.includes('user')) dispatch(UserAllRequestData());
  }, [filterTypes.includes('user')])

  const status = [
    { label: "Active", value: "true" },
    { label: "InActive", value: "false" },
  ]


  return (
    <Box sx={{ display: 'flex', padding: 2, paddingTop: 0, justifyContent: 'space-between' }}>
      {/* <form onKeyPress={(e:any)=>onEnterPress(e)} onSubmit={(e: any) => setFilterButton(e)}> */}
      <Box>
        <FormGroup className="MuiFormGroup-options" row>
          {filterTypes.includes('search') && <FormControl sx={{ mt: 3, mr: 2 }} variant="standard">
            <TextField
              multiline={multilinesearch ? true : false}
              fullWidth
              name="search"
              maxRows={searchheight}
              variant="standard"
              sx={{ width: searchwidth || 300 }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search…"
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }
              }
            />

          </FormControl>}

          {filterTypes.includes('searcharea') && <FormControl sx={{ mt: 3, mr: 2 }} variant="standard">
            <TextField
              minRows={5}
              maxRows={8}
              multiline={true}
              fullWidth
              name="search"
              variant="standard"
              sx={{ width: 400 }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search…"
            />
          </FormControl>}

          {filterTypes.includes('countries') && <FormControl sx={{ m: 1, minWidth: 150 }} variant="standard">
            <InputLabel>Countries</InputLabel>
            <Select name="countryId" defaultValue="" value={getFilterItem('countryId')} onChange={(e) => setFilterItem('countryId', e.target.value)} >
              <MenuItem value="">any</MenuItem>
              {countries.map((country: Country) =>
                <MenuItem key={country._id} value={country._id}>{country.label}</MenuItem>
              )}
            </Select>
          </FormControl>}
          {filterTypes.includes('project') && <FormControl sx={{ m: 1, minWidth: 150 }} variant="standard">
            <InputLabel>Group Projects</InputLabel>
            <Select name="project" defaultValue="" value={getFilterItem('project')} onChange={(e) => setFilterItem('project', e.target.value)} >
              {projects && projects.map((project: Project) =>
                <MenuItem key={project.value} value={project.value}>{project.code + "_" + project.label}</MenuItem>
              )}
            </Select>
          </FormControl>}
          {filterTypes.includes('client') && <FormControl sx={{ m: 1, minWidth: 150 }} variant="standard">
            <InputLabel> Clients</InputLabel>
            <Select name="client" defaultValue="" value={getFilterItem('client')} onChange={(e) => setFilterItem('client', e.target.value)} >
              <MenuItem value="">any</MenuItem>
              {clients.map((client: Client) =>
                <MenuItem key={client._id} value={client._id}>{client.label}</MenuItem>
              )}
            </Select>
          </FormControl>}
          {filterTypes.includes('clientdiy') && <FormControl sx={{ m: 1, minWidth: 150 }} variant="standard">
            <InputLabel> Clients</InputLabel>
            <Select name="client" defaultValue="" value={getFilterItem('client')} onChange={(e) => setFilterItem('client', e.target.value)} >
              <MenuItem value="">any</MenuItem>
              {clients.map((client: Client) =>
                <MenuItem key={client._id} value={client._id}>{client.clientName}</MenuItem>
              )}
            </Select>
          </FormControl>}
          {filterTypes.includes('supplier') && <FormControl sx={{ m: 1, minWidth: 150 }} variant="standard">
            <InputLabel> Suppliers</InputLabel>
            <Select name="supplier" defaultValue="" value={getFilterItem('supplier')} onChange={(e) => setFilterItem('supplier', e.target.value)} >
              <MenuItem value="">any</MenuItem>
              {suppliers.map((supplier: Supplier) =>
                <MenuItem key={supplier._id} value={supplier._id}>{supplier.supplierName}</MenuItem>
              )}
            </Select>
          </FormControl>}
          {filterTypes.includes('status') &&
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} >
              <InputLabel >Status</InputLabel>
              <Select name="isActive" defaultValue="" value={getFilterItem('isActive')} onChange={(e) => setFilterItem('isActive', e.target.value)} >
                <MenuItem value="">any</MenuItem>
                <MenuItem value="true">true</MenuItem>
                <MenuItem value="false">false</MenuItem>
              </Select>
            </FormControl>}
          {filterTypes.includes('user') &&
            <FormControl variant="standard" sx={{ m: 1, minWidth: 140 }} >
              <InputLabel >Manager </InputLabel>
              <Select name="user" defaultValue="" value={getFilterItem('user')} onChange={(e) => setFilterItem('user', e.target.value)} >
                <MenuItem value="">any</MenuItem>
                {users.map((user: adminInfoProps) => <MenuItem key={user._id} value={user._id}>{user.label}</MenuItem>)}
              </Select>
            </FormControl>}
          {filterTypes.includes('projectStatus') &&
            <FormControl variant="standard" sx={{ m: 1, minWidth: 140 }} >
              <InputLabel >Project Status</InputLabel>
              <Select name="projectStatus" defaultValue="" value={getFilterItem('projectStatus')} onChange={(e) => setFilterItem('projectStatus', e.target.value)} >
                <MenuItem value="">any</MenuItem>
                {ProjectStatus.map((status: any) => <MenuItem key={status.value} value={status.value}>{status.label}</MenuItem>)}
              </Select>
            </FormControl>}
          {filterTypes.includes('sortDate') && <Box>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 140 }} >
              <InputLabel >Sort By</InputLabel>
              <Select name="sortDate" value={getFilterItem('sortDate')} onChange={(e) => setFilterItem('sortDate', e.target.value)} >
                <MenuItem value={1}>Today</MenuItem>
                <MenuItem value={2}>Last 7 Days</MenuItem>
                <MenuItem value={3}>This Month</MenuItem>
              </Select>
            </FormControl>
          </Box>}
          {filterTypes.includes('dateRange') && <Box>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 140 }} >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Min Date"
                  onChange={(date: Date | null) => setFilterItem('minDate', date)}
                  value={getFilterItem('minDate') || null}
                  renderInput={(params) => <TextField
                    variant="standard"
                    fullWidth {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 140 }} >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  minDate={getFilterItem('minDate')}
                  inputFormat="dd/MM/yyyy"
                  label="Max Date"
                  value={getFilterItem('maxDate') || null}
                  onChange={(date: Date | null) => setFilterItem('maxDate', date)}
                  renderInput={(params) => <TextField
                    variant="standard"
                    fullWidth {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>}
          {filterTypes.includes('exportIdentifier') &&
          <FormControl sx={{ mt: 3, mr: 2 }} variant="standard">
              <LoadingButton
                loading={exportIdentifierLoading}
                onClick={()=>exportIdentfier()}
                variant="contained"

              >
                Export
              </LoadingButton>
              </FormControl>
            }
        </FormGroup>
      </Box>
      <Box>
        <FormGroup className="MuiFormGroup-options" row>
          <Box sx={{ display: 'flex', justifyContent: 'center', ml: 1, mt: 3 }}>
            {urlAction && urlAction('Post Back Redirects', metadata.postBacks)}
            {notifyAction && notifyAction('Notify Url Redirects', metadata.notifyUrls)}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', ml: 1, mt: 3 }}>
            {reloadAction && <IconButton onClick={() => reloadAction()}><CachedIcon /></IconButton>}
          </Box>
          {filterTypes.includes('export') &&
            <FormControl variant="standard" sx={{ mt: 3, ml: 1, minWidth: 10 }} >
              <IconButton onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage, fileName: exportFileName })}>
                <Download />
              </IconButton>
            </FormControl>}

        </FormGroup>
      </Box>
    </Box>
  );
}

export default ListToolbar;
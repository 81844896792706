import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Divider, Box } from '@mui/material';
import { FC } from 'react';
import swal from 'sweetalert';
import { useUser } from 'src/layouts/dashboard/DashboardLayout';

const MoreMenu: FC<any> = ({ addAction, project,revokeProject,emailActionClient,groupPrescreenReport, dialogActionsEmail,dialogActionsLog,emailActionSupplier, addToProject,archievedUpdateAction,editStatusAction, editAction,exportFileName,reconcileUpdateAction,revertAction, deleteAction,reconsileAction, exportAction, cloneAction, id ,name,exportSupplierMap,exportPrescreenReport,downloadInvoiceAction,viewInvoiceAction }) => {
  const { adminUser } = useUser()

  const deleteActionAlert = (id: string) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteAction(id);
        } else { }
      });

  }

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton disabled={adminUser?.role === 'SUBSCRIBER' || adminUser?.role === 'BDE'} ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {addToProject && <MenuItem sx={{ color: 'text.secondary' }} onClick={() => addToProject(project)}>
          <ListItemIcon>
            <Icon icon={'carbon:parent-child'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Add Project" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {addAction && <MenuItem sx={{ color: 'text.secondary' }} onClick={() => addAction(id)}>
          <ListItemIcon>
            <Icon icon={'carbon:parent-child'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Child" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {cloneAction && <MenuItem sx={{ color: 'text.secondary' }} onClick={() => cloneAction(id)}>
          <ListItemIcon>
            <Icon icon={'clarity:clone-solid'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Clone" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {editAction && <MenuItem sx={{ color: 'text.secondary' }} onClick={() => editAction(id)}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {editStatusAction && <MenuItem sx={{ color: 'text.secondary' }} onClick={() => editStatusAction(id)}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit Status" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {revertAction && 
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => revertAction(id)}>
          <ListItemIcon>
            <Icon icon={'eva:undo-fill'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`Undo Reconcile`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {reconsileAction && reconsileAction(id)}
        {reconcileUpdateAction && 
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => reconcileUpdateAction(id,project)}>
          <ListItemIcon>
            <Icon icon={'nimbus:invoice'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`Proceed to Invoice`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {revokeProject && 
          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => revokeProject(id)}>
          <ListItemIcon>
            <Icon icon={'clarity:clone-line'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`Revoke Project`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {emailActionSupplier && 
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => emailActionSupplier(id)}>
          <ListItemIcon>
            <Icon icon={'carbon:email'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`RFQ Email`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {emailActionClient && 
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => emailActionClient(id)}>
          <ListItemIcon>
            <Icon icon={'carbon:email'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`Progress Report`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {dialogActionsEmail && dialogActionsEmail(id,name)}
        {dialogActionsLog && dialogActionsLog(id,name)}
        {archievedUpdateAction && 
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => archievedUpdateAction(id)}>
          <ListItemIcon>
            <Icon icon={'fluent:delete-arrow-back-16-filled'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`Archieve`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {viewInvoiceAction && 
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => viewInvoiceAction(id)}>
          <ListItemIcon>
            <Icon icon={'ant-design:download-outlined'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`View Invoice`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {downloadInvoiceAction && 
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => downloadInvoiceAction(id)}>
          <ListItemIcon>
            <Icon icon={'ant-design:download-outlined'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`Download Invoice`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {exportAction && 
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => exportAction(id,name)}>
          <ListItemIcon>
            <Icon icon={'ant-design:download-outlined'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`${exportFileName} data`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {exportPrescreenReport &&  
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => exportPrescreenReport(id,name)}>
          <ListItemIcon>
            <Icon icon={'ant-design:download-outlined'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Prescreen Data" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {exportSupplierMap && 
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => exportSupplierMap(id,name)}>
          <ListItemIcon>
            <Icon icon={'ant-design:download-outlined'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Supplier Data" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {groupPrescreenReport && groupPrescreenReport(id,name)}
        {deleteAction && <Box>
            <Divider />
            <MenuItem sx={{ color: 'text.red' }} onClick={() => deleteActionAlert(id)}>
              <ListItemIcon>
                <Icon icon={trash2Outline} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </Box> }
      </Menu>
    </>
  );
}

export default MoreMenu;
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Switch, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { MoreMenu, DataGridRender } from 'src/components/table';
import MessageContext from 'src/context/MessageContext';
import TabLayout from 'src/components/tab/TabLayout';
import TopBar from 'src/components/shared/TopBar';
import { SupplierRuleAllRequest, SupplierRuleDeleteRequest } from 'src/actions/supplierRuleActions';

export default function SupplierRuleList() {

  const { setMessageHandler } = useContext<any>(MessageContext);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>([]);
  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch();
  const { loading, metadata, data } = useSelector(state => state.supplierRule);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(SupplierRuleAllRequest(filters, search, pageNo, limit, status));
  }, [pageNo, search, limit, status, filters]);

  const deleteAction = (id: string) => {
    dispatch(SupplierRuleDeleteRequest(id, { isDeleted: true }, setMessageHandler));
  }
  const editAction = (id: string) => {
    navigate(`/admin/library/rule/${id}/edit`);
  }

  const columns = [
    { field: 'minSupplierCpi', type: "string", headerName: 'Minimum Supplier CPI', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    { field: 'maxSupplierCpi', type: "string", headerName: 'Maximum Supplier CPI', filterable: false, headerAlign: "center", align: 'center', flex: 1 },
    { field: 'actions', headerName: '', width: 100, headerAlign: "right", align: 'right', type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['categoryTitle']} cloneAction="" addAction='' editAction={editAction} deleteAction={deleteAction} exportAction="" />] },
  ];


  return (
    <Container maxWidth={false}>
      <TopBar title="Library" link="/admin/library/rule/add" buttonText="Add Supplier Rule" />
      <TabLayout handleChange="" tabValue={3}>
        <Tab label="Categories" onClick={() => navigate("/admin/library")} value={1} />
        <Tab label="Questions" onClick={() => navigate("/admin/library/question")} value={2} />
        <Tab label="Supplier Rules" onClick={() => navigate("/admin/library/rule")} value={3} />
        <Tab label="Qualfications" onClick={() => navigate("/admin/library/qualification")} value={4} />
      </TabLayout>
      <DataGridRender
        loading={loading}
        filterTypes={['status', 'search', 'export']}
        exportFileName={"Supplier Rules"}
        data={data}
        metadata={metadata}
        limit={limit}
        search={search}
        filters={filters}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setSearch={setSearch}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}

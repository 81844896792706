import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Container, Typography, IconButton, Box, Tooltip, Badge, Switch, ListItemIcon, ListItemText, Grid, Tab } from '@mui/material';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { sentenceCase } from 'change-case';
import ReactCountryFlag from 'react-country-flag';
import swal from 'sweetalert';
import { MoreMenu, DataGridRender } from 'src/components/table';
import Label from 'src/components/Label';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SupplierMapAllRequest, SupplierMapStatusUpdateRequest, SupplierProjectMapAllRequest } from 'src/actions/supplierMapActions';
import { ProjectAllRequest, ProjectChildsRequest, ProjectGroupExportRequest, ProjectGroupPrescreenExportRequest, ProjectGroupSupplierMapExportRequest, ProjectResetSuccess, ProjectUpdateByCodeRequest, ProjectUpdateRequest } from 'src/actions/projectActions';
import { ProjectStatus } from 'src/constants';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';
import TopBar from 'src/components/shared/TopBar';
import { fShortenNumber } from 'src/utils/formatNumber';
import { ProjectStats } from 'src/components/admin/_dashboard/app/Dashboard';
import DrawerLayout from 'src/components/drawer/DrawerLayout';
import { MessageContext } from 'src/context';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import EmailForm from 'src/components/admin/_dashboard/email/EmailForm';
import SystemLogTimeline from 'src/components/admin/_dashboard/app/Dashboard/SystemLogTimeline';
import { SendEmailMapRequest, SendEmailQuotaRequest, SendEmailReportRequest, SendEmailRequest } from 'src/actions/emailActions';
import { LoadingButton } from '@mui/lab';
import PrescreenForm from 'src/components/admin/_dashboard/prescreen/PrescreenForm';
import RenderGraph from 'src/components/admin/_dashboard/app/Dashboard/RenderGraph';
import CloseProjectForm from 'src/components/admin/_dashboard/action/CloseProjectForm';

export default function ProjectList() {

  const { state } = useLocation();
  const { setMessageHandler } = useContext<any>(MessageContext);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [dialogSupplier, setDialogSupplier] = useState<string>('');
  const [dialogEmail, setDialogEmail] = useState<string>('');
  const [dialogLog, setDialogLog] = useState<string>('');
  const [searchSupplier, setSearchSupplier] = useState<string>('');
  const [limit, setLimit] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [dialog, setDialog] = useState<string>('');
  const [dialogSupplierMap, setDialogSupplierMap] = useState<string>('');
  const [dialogProjectClose, setDialogProjectClose] = useState<string>('');
  
  const [selectionModel, setSelectionModel] = useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuid, setMenuId] = useState<string>('');
  const open = Boolean(anchorEl);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setMenuId(id)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const { loading, childProjects, redirectTo, metadata, data } = useSelector(state => state.project);
  const supp = useSelector(state => state.supplierMap);

  const navigate = useNavigate();

  var dates: any = [];
  var completes: any = [];
  var terminate: any = [];
  var overQuota: any = [];

  supp && supp.supplierMaps.map((expense: any) =>
    dates.push(expense.supplierName) && completes.push(expense.complete)  && terminate.push(expense.terminate) &&
    overQuota.push(expense.overQuota) 
  );

  const CHART_DATA = [
    {
      name: 'Completes',
      data: completes
    },
    {
      name: 'Terminates',
      data: terminate
    },
    {
      name: 'Over Quota',
      data: overQuota
    },

  ];


  var options: any = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: dates
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return  val
        }
      }
    }
  };

  useEffect(() => {
    if (state) dispatch(ProjectAllRequest({ search: state }, pageNo, limit, status))
    else { dispatch(ProjectAllRequest(filters, pageNo, limit, status)) }
  }, [pageNo, limit, filters, status, state]);

  useEffect(() => {
    if (dialogSupplier)
      dispatch(SupplierMapAllRequest(dialogSupplier, searchSupplier));
  }, [dialogSupplier, searchSupplier]);

  useEffect(() => {
    if (dialogSupplierMap)
      dispatch(SupplierProjectMapAllRequest(dialogSupplierMap));
  }, [dialogSupplierMap]);

  useEffect(() => {
    if (redirectTo) {
      setDialog('');
      dispatch(ProjectAllRequest(filters, pageNo, limit, status))
    }
  }, [pageNo, limit, filters, status, redirectTo]);


  const editAction = (id: string) => {
    navigate(`/admin/project/${id}/edit`, { replace: true });
  }

  const exportAction = (id: string, name: string) => {
    dispatch(ProjectGroupExportRequest({ projectId: id }, name));
  }
  const addAction = (id: string) => {
    navigate(`/admin/project/child/${id}/add`, { replace: true });
  }

  const exportSupplierMap = (id: string, name: string) => {
    dispatch(ProjectGroupSupplierMapExportRequest({ projectId: id }, name));
  }

  const sendEmailAction = (values: any, setSubmitting: any) => {
    dispatch(SendEmailRequest({ type: 'project', projectId: values.projectId, emailBody: values.emailBody }, setDialogEmail, setMessageHandler));
  }

  const closeProjectAction =(data:any ,setSubmitting:any ) =>{
    dispatch(ProjectUpdateByCodeRequest(data.projectCode,{ poNumber:data.poNumber },setMessageHandler,setSubmitting ,setDialogProjectClose));
  }


  const columnsRedirect = [
    {
      field: 'label', type: "string", headerName: 'Type', filterable: false, headerAlign: "left", align: 'left', width: 220, pinnable: true, renderCell: ({ id, row, value }: any) => {
        return <Link to={`/admin/project/${id}/childs`}>
          <Typography color="primary" >{value}</Typography>
        </Link>
      }
    },
    {
      field: 'url', type: "string", headerName: 'Redirect Url', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
  ];


  const suppliercolumns = [
    {
      field: 'supplierName', type: "string", headerName: 'Supplier Name', filterable: false, headerAlign: "center", align: 'center', flex: 1, renderCell: ({ value }: any) => {
        return <Tooltip title={value}><Typography color="primary" >{value}</Typography></Tooltip>
      }
    },
    {
      field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 100, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['complete'])}`;
      },
    },
    { field: 'terminate', type: "number", headerName: 'ST', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'overQuota', type: "number", headerName: 'OQ', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'qualityTerm', type: "number", headerName: 'QT', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'drop', type: "number", headerName: 'DO', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'preScreenTerminate', type: "number", headerName: 'PT', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'preScreenDrop', type: "number", headerName: 'PD', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'totalResponded', type: "number", headerName: 'Traffic', filterable: false, width: 80, headerAlign: "center", align: 'center' },
    { field: 'supplierCPI', type: "number", headerName: 'CPI', filterable: false, width: 80, headerAlign: "center", align: 'center' },
    {
      field: 'allowTraffic', headerName: 'Traffic', type: "number", width: 80, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { allowTraffic: !params.value }, setMessageHandler))} />
      }
    },
    {
      field: 'isTestLink', headerName: 'Test Link', type: "number", width: 90, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isTestLink: !params.value }, setMessageHandler))} />
      }
    },
    {
      field: 'isPreScreen', headerName: 'PreScreen', type: "number", width: 100, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isPreScreen: !params.value }, setMessageHandler))} />
      }
    },
    {
      field: 'isBoarded', headerName: 'Boarded', type: "number", width: 90, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isBoarded: !params.value }, setMessageHandler))} />
      }
    },
  ];

  const urlAction = (title: string, links: any) => {
    return <Box>
      <IconButton onClick={() => setDialog(title)}><LinkIcon /></IconButton>
      <ResponsiveDialog openDialog={dialog == title} setOpenDialog={setDialog} title={title} subTitle="" >
        <DataGridRender
          height={400}
          width={1200}
          loading={loading}
          filterTypes={['export']}
          data={links}
          filters={filters}
          exportFileName="Redirects"
          metadata={metadata}
          limit={limit}
          pageNo={pageNo}
          onCellEditCommit={onCellEditCommit}
          columns={columnsRedirect}
          setPageNo={setPageNo}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />
      </ResponsiveDialog>
    </Box>
  }

  const notifyAction = (title: string, links: any) => {
    return <Box>
      <IconButton onClick={() => setDialog(title)}><NotificationsIcon /></IconButton>
      <ResponsiveDialog openDialog={dialog == title} setOpenDialog={setDialog} title={title} subTitle="" >
        <DataGridRender
          height={400}
          width={1200}
          loading={loading}
          filterTypes={['export']}
          data={links}
          filters={filters}
          exportFileName="Redirects"
          metadata={metadata}
          limit={limit}
          pageNo={pageNo}
          onCellEditCommit={onCellEditCommit}
          columns={columnsRedirect}
          setPageNo={setPageNo}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />
      </ResponsiveDialog>
    </Box>
  }


  const dialogActions = (projectRedirects: any, id: string, name: string, title: string) => {
    return <Box>
      <IconButton onClick={() => setDialog(id + title)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialog == id + title} setOpenDialog={setDialog} title={title} subTitle={name}  >
        <DataGridRender
          height={400}
          width={800}
          loading={loading}
          filterTypes={['export']}
          data={projectRedirects}
          filters={filters}
          exportFileName="Redirects"
          metadata={metadata}
          limit={limit}
          pageNo={pageNo}
          onCellEditCommit={onCellEditCommit}
          columns={columnsRedirect}
          setPageNo={setPageNo}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />
      </ResponsiveDialog>
    </Box>
  }

  const dialogActionsStats = (stats: any, id: string, subTitle: string, title: string) => {
    return <Box>
      {/* <IconButton  onClick={() => setDialog(id + title)}>
        <Icon icon="mdi:user" />
      </IconButton> */}
      <IconButton onClick={() => setDialogSupplierMap(id)}>
      <VisibilityIcon />
      </IconButton>
      {/* <ResponsiveDialog openDialog={dialog == id + title} setOpenDialog={setDialog} title={title} subTitle={subTitle} >
        <ProjectStats stats={stats} />
      </ResponsiveDialog> */}
      <ResponsiveDialog customWidth={1000} openDialog={dialogSupplierMap == id } setOpenDialog={setDialogSupplierMap} title="Supplier Stats" subTitle={subTitle} >
      <ProjectStats stats={stats} />
      <RenderGraph filterType={['']} title="" subheader="" chartdata={CHART_DATA} options={options} type="bar" />
      </ResponsiveDialog>
    </Box>
  }

  const dialogActionsSecurity = (stats: any, id: string, title: any, name: string, count: number) => {
    return <Box>
      <IconButton onClick={() => setDialog(id + title)}>
        <Badge showZero={true} max={100000} badgeContent={count} color="primary">
        </Badge>
      </IconButton>
      <ResponsiveDialog openDialog={dialog == id + title} setOpenDialog={setDialog} title={title} subTitle={name} >
        <ProjectStats stats={stats} />
      </ResponsiveDialog>
    </Box>
  }

  const dialogActionsSupplier = (filename: string, id: string, title: any, subTitle: string) => {
    return <Box>
      <IconButton onClick={() => setDialogSupplier(id)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialogSupplier == id} subTitle={subTitle} setOpenDialog={setDialogSupplier} title={title} >
        <DataGridRender
          height={supp.supplierMaps.length == 0 ? 40 + 230 : supp.supplierMaps.length * 40 + 230}
          width={1200}
          getRowClassName={(params: any) => params.row['complete'] >= params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
          filterTypes={['export']}
          data={supp.supplierMaps}
          metadata=""
          loading={supp.loading}
          limit={limit}
          filters={filters}
          pageNo={pageNo}
          search={searchSupplier}
          exportFileName={filename}
          setSearch={setSearchSupplier}
          columns={suppliercolumns}
          hideFooterPagination={true}
          setPageNo={setPageNo}
          setLimit={setLimit}
          setFilters={setFilters}
        />
      </ResponsiveDialog>
    </Box>
  }


  const dialogActionsEmail = (id: string, title: any) => {
    const initialValues = { projectId: id }
    return <Box>
      <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setDialogEmail(id + title)}>
        <ListItemIcon>
          <Icon icon={'carbon:email'} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary={`Send Email`} primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <ResponsiveDialog openDialog={dialogEmail == id + title} subTitle="Email Action" setOpenDialog={setDialogEmail} title={title} >
        <EmailForm initialValues={initialValues} submitForm={sendEmailAction} />
      </ResponsiveDialog>
    </Box>
  }

  const dialogActionsLog = (id: string, title: any, name: string) => {
    return <Box>
      <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setDialogLog(id + title)}>
        <ListItemIcon>
          <Icon icon={'carbon:flow-logs-vpc'} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary={`Logs`} primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <ResponsiveDialog openDialog={dialogLog == id + title} setOpenDialog={setDialogLog} title={title} subTitle={name} >
        <SystemLogTimeline projectid={id} />
      </ResponsiveDialog>
    </Box>
  }

  const groupPrescreenReport = (id: string, title: any) => {
    return <Box>
      <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setDialog(id + title)}>
        <ListItemIcon>
          <Icon icon={'ant-design:download-outlined'} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary={`Prescreen Data`} primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <ResponsiveDialog openDialog={dialog == id + title} subTitle="" setOpenDialog={setDialog} title={title} >
        <PrescreenForm title={title} projectId={id} submitForm={downloadPrescreenReport} />
      </ResponsiveDialog>
    </Box>
  }

  const downloadPrescreenReport = (projectId: string, title: string, values: any, setSubmitting: any) => {
    values.projectId = projectId;
    dispatch(ProjectGroupPrescreenExportRequest(values, title, setDialog));
  }

  const toggleDrawer = (anchor: string, open: boolean) => {
    if (!open) {
      setSelectionModel([]);
      reloadAction();
    }
    setOpenDrawer(open);
  };

  const onSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel)
    toggleDrawer("bottom", true);
    dispatch(ProjectChildsRequest(newSelectionModel[0], {}));
  }

  const reloadAction = () => {
    dispatch(ProjectAllRequest(filters, pageNo, limit, status));
  }

  const setSubmitting = () => { }

  const emailActionSupplier = (id: string) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      dangerMode: false,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(SendEmailMapRequest({ projectId: id }, setMessageHandler));
        } else {
        }
      });
  }

  const emailActionClient = (id: string) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      dangerMode: false,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(SendEmailReportRequest({ projectId: id }, setMessageHandler));
        } else {
        }
      });
  }


  const onCellEditCommit = (params: any) => {
    dispatch(ProjectUpdateRequest(params.id, { [params.field]: Number(params.value) + Number(params.row['complete']) }, setMessageHandler, setSubmitting))
  }

  const emailSendQuotaAction = () => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      dangerMode: false,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(SendEmailQuotaRequest({ projectId: selectionModel[0] }, setMessageHandler));
        } else {
        }
      });
  }


  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 90, pinnable: true, renderCell: ({ id, row, value }: any) => {
        return <Link to={`/admin/project/${id}/childs`}>
          <Typography color="primary" >{value}</Typography>
        </Link>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'clienName', type: "string", headerName: 'Client Name', filterable: false, headerAlign: "left", align: 'left', width: 120, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 100, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['complete']}/${row['sampleSize']}`;
      },
    },
    { field: 'terminate', type: "string", headerName: 'ST', filterable: false, width: 50, headerAlign: "center", align: 'center' },
    { field: 'overQuota', type: "string", headerName: 'OQ', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'qualityTerm', type: "string", headerName: 'QT', filterable: false, width: 50, headerAlign: "center", align: 'center' },
    { field: 'drop', type: "string", headerName: 'DO', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    {
      field: 'totalDisqualified', type: "string", headerName: 'Security', filterable: false, width: 90, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsSecurity(row.disqualified, row._id,
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Security</Typography>
          </Box>, row.projectCode, row.totalDisqualified);
      },
    },
    { field: 'clicked', type: "string", headerName: 'Clicks', filterable: false, width: 70, headerAlign: "center", align: 'center' },
    {
      field: 'cpi', type: "string", headerName: 'CPI', filterable: false, width: 60, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['cpi'])}`;
      },
    },
    {
      field: 'fstats', type: "string", headerName: 'Stats', disableExport: true, filterable: false, width: 110, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsStats(row.fieldStats, row._id, row.projectCode, 'Field Stats');
      },
    },
    {
      field: 'url', type: "string", headerName: 'Redirects', disableExport: true, filterable: false, width: 95, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActions(row.projectRedirects, row._id, row.projectCode, 'Redirects');
      },
    },
    {
      field: 'projectStatus', headerName: 'Status', type: "number", width: 80, align: 'center',
      renderCell: (params: any) => {
        var status: any = ProjectStatus.find(status => status.value == params.value);
        return <>
          <IconButton onClick={(e) => handleClickMenu(e, params.row._id)}>
            <Label
              variant="ghost"
              color={status?.color}
            >
              {sentenceCase(status.label)}
            </Label>
          </IconButton>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open && params.row._id == menuid}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
           {params.value !=  9 && <MenuItem onClick={() => dispatch(ProjectUpdateRequest(params.row._id, { projectStatus: 9 }, setMessageHandler, setSubmitting)) && handleClose()}>Testing</MenuItem>}
           {params.value !=  1 && <MenuItem onClick={() => dispatch(ProjectUpdateRequest(params.row._id, { projectStatus: 1 }, setMessageHandler, setSubmitting)) && handleClose()}>Live</MenuItem>}
           {params.value !=  3 &&  <MenuItem onClick={() => dispatch(ProjectUpdateRequest(params.row._id, { projectStatus: 3 }, setMessageHandler, setSubmitting)) && handleClose()}>Paused</MenuItem>}
           {params.value !=  8 && <MenuItem onClick={() => dispatch(ProjectUpdateRequest(params.row._id, { projectStatus: 8 }, setMessageHandler, setSubmitting)) && handleClose()}>Waiting</MenuItem>}
            <MenuItem onClick={() => setDialogProjectClose(params.row.projectCode)  
            }>Closed</MenuItem>
          </Menu>
        </>
      }
    },
    { field: 'actions', headerName: '', width: 30, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['projectCode'] + '_' + row['projectName']} projectStatus={row['projectStatus']} exportFileName="Project" reconsileAction="" cloneAction='' addAction={addAction} editAction={editAction} deleteAction="" exportSupplierMap={exportSupplierMap} exportAction={exportAction} dialogActionsEmail={dialogActionsEmail} dialogActionsLog={dialogActionsLog} emailActionSupplier={emailActionSupplier} groupPrescreenReport={groupPrescreenReport} emailActionClient={emailActionClient} />] },
  ];


  const columnsDrawer = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 120, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Link to={`/admin/project/child/${id}/edit`}>
          <Typography color="primary" >{value}</Typography>
        </Link>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'countryName', type: "string", headerName: 'Region', filterable: false, width: 120, headerAlign: "left", align: 'left',
      renderCell: (params: any) => {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReactCountryFlag className="emojiFlag" countryCode={`${params.row.countryCode}`} /><Tooltip title={params.row.countryName}>
            <Typography sx={{ ml: 1 }} variant="subtitle2">{params.row.countryName}</Typography>
          </Tooltip>
        </Box>
      },
    },
    {
      field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 100, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['complete']}/${row['sampleSize']}`;
      },
    },
    { field: 'terminate', type: "string", headerName: 'ST', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'overQuota', type: "string", headerName: 'OQ', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'qualityTerm', type: "string", headerName: 'QT', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'drop', type: "string", headerName: 'DO', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    {
      field: 'totalDisqualified', type: "string", headerName: 'Security', filterable: false, width: 90, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsSecurity(row.disqualified, row._id,
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Security</Typography>
          </Box>, row.projectCode, row.totalDisqualified);
      },
    },
    { field: 'clicked', type: "string", headerName: 'Traffic', filterable: false, width: 80, headerAlign: "center", align: 'center' },
    {
      field: 'cpi', type: "string", headerName: 'CPI', filterable: false, width: 60, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['cpi'])}`;
      },
    },
    {
      field: 'sstats', type: "string", headerName: 'Supplier Stats', disableExport: true, filterable: false, width: 130, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsSupplier(row['projectCode'] + "_" + row['projectName'], row._id, row.projectCode, 'Supplier Mapped');
      },
    },
    {
      field: 'sampleSize', type: "number", headerName: 'Sample Size', editable: true,
      filterable: false, width: 120, headerAlign: "center", align: 'center'
    },
    {
      field: 'sampleSizeInc', type: "number", headerName: 'Sample Size+', editable: true,
      filterable: false, width: 120, headerAlign: "center", align: 'center'
    },
    {
      field: 'respondentClickQuota', type: "number", headerName: 'Click Quota', editable: true,
      filterable: false, width: 120, headerAlign: "center", align: 'center'
    },
  ];
  const initialDataValues ={ dialogProjectClose }

  return (
    <Container maxWidth={false}>
      <TopBar title="Projects" link="/admin/project/add" buttonText="Add Project" />
      <ResponsiveDialog openDialog={!dialogProjectClose == false} subTitle="Close Action" setOpenDialog={setDialogProjectClose} title={dialogProjectClose} >
        <CloseProjectForm initialValues={initialDataValues} submitForm={closeProjectAction} />
      </ResponsiveDialog>
      <DrawerLayout openDrawer={openDrawer} toggleDrawer={toggleDrawer} >
        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mt: 1, mb: 2 }} xs={12}>
          <LoadingButton
            size="medium"
            type="submit"
            variant="contained"
            onClick={() => emailSendQuotaAction()}
          >
            Send Email
          </LoadingButton>
        </Grid>
        <DataGridRender
          height={childProjects.length == 0 ? 40 + 120 : childProjects.length * 40 + 120}
          getRowclassNameName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
          loading={loading}
          toolbar={true}
          filterTypes={['export']}
          data={childProjects}
          filters={filters}
          exportFileName="Projects"
          metadata={metadata}
          limit={limit}
          pageNo={pageNo}
          onCellEditCommit={onCellEditCommit}
          columns={columnsDrawer}
          setPageNo={setPageNo}
          setLimit={setLimit}
          setFilters={setFilters}
          hideFooterPagination={true}
        />
      </DrawerLayout>
      <DataGridRender
        state={state}
        checkboxSelection
        onSelectionModelChange={onSelectionModelChange}
        reloadAction={reloadAction}
        urlAction={urlAction}
        notifyAction={notifyAction}
        getRowclassNameName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
        loading={loading}
        filterTypes={['client', 'user', 'url', 'notify', 'export', 'search', 'projectStatus']}
        data={data}
        filters={filters}
        selectionModel={selectionModel}
        exportFileName="Projects"
        metadata={metadata}
        limit={limit}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}

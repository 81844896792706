import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link, } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Container, Typography, Box, Tooltip, TextField, Button, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { sentenceCase } from 'change-case';
import plusFill from '@iconify/icons-eva/plus-fill';
import CSVReader from 'react-csv-reader'
import swal from 'sweetalert';
import { DataGridRender, MoreMenu } from 'src/components/table';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Label from 'src/components/Label';
import { ProjectAllRequest, ProjectGroupExportRequest,  ProjectReconsileData, ProjectReconsileUndoData, ProjectMoveToInvoice } from 'src/actions/projectActions';
import { ProjectStatus } from 'src/constants';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';
import TopBar from 'src/components/shared/TopBar';
import { fShortenNumber } from 'src/utils/formatNumber';
import { MessageContext } from 'src/context';
import { SupplierAllSummaryData, supplierSummaryApprovedAction } from 'src/actions/supplierMapActions';

const papaparseOptions: any = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: any) =>
    header
      .toLowerCase()
      .replace(/\W/g, '_')
}

export default function ReconcileProjectList() {

  const { setMessageHandler } = useContext<any>(MessageContext);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(20);
  const [reconsileData, setReconsileData] = useState<any>([]);
  const [reconsileType, setReconsileType] = useState<number>();
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({ projectStatus: 6 });
  const [dialog, setDialog] = useState<string>('');
  const [dialogSupplier, setDialogSupplier] = useState<string>('');

  const dispatch = useDispatch();
  const { loading, metadata, data, redirectTo } = useSelector(state => state.project);
  const { supplierMaps } = useSelector(state => state.supplierMap);


  useEffect(() => {
     dispatch(ProjectAllRequest(filters, pageNo, limit, status))
  }, [pageNo, limit, filters, status]);

  useEffect(() => {
    if (redirectTo) {
      setReconsileData([]);
      setReconsileType(0);
      dispatch(ProjectAllRequest(filters, pageNo, limit, status))
    }
  }, [redirectTo]);


  const revertAction = (id: string) => {
    swal({
      title: "Do you sure?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(ProjectReconsileUndoData(id, setMessageHandler));
        } else {

        }
      });
  }

  const reconcileUpdateAction = (id: string,project:any) => {
    swal({
      title: "Are you sure to invoice ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No!", "Yes"],
    }).then((willDelete) => {
        if (willDelete) {
          swal({
            title: "Do you want to send email to supplier ?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No!", "Yes"],
          }).then((willDele) =>{
            if(willDele) dispatch(ProjectMoveToInvoice(id, { projectStatus: 4 ,sendEmail:true }, setMessageHandler));
            else dispatch(ProjectMoveToInvoice(id, { projectStatus: 4 ,sendEmail:false }, setMessageHandler));
          });
        } else {
          
        }
     });
  }

  const archievedUpdateAction = (id: string) => {
    swal({
      title: "Are you sure to archieve ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No!", "Yes"],
    }).then((willDelete) => {
        if (willDelete) {
          swal({
            title: "Do you want to send email to supplier ?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No!", "Yes"],
          }).then((willDele) =>{
            if(willDele) dispatch(ProjectMoveToInvoice(id, { projectStatus: 7 ,sendEmail:true}, setMessageHandler));
            else dispatch(ProjectMoveToInvoice(id, { projectStatus: 7 ,sendEmail:false }, setMessageHandler));
          });
        } else {
          
        }
     });
  }

  useEffect(() => {
    if (dialogSupplier)
    dispatch(SupplierAllSummaryData({ projectId: dialogSupplier }));
  }, [dialogSupplier]);

  
  const onCellEditCommit = (params: any) => {
   dispatch(supplierSummaryApprovedAction({[params.field]:params.value, projectId:dialogSupplier,supplierId:params.row._id },setMessageHandler))
  }

  const exportAction = (id: string, name: string) => {
    dispatch(ProjectGroupExportRequest({ projectId: id }, name));
  }
  const reconsileAction = (id: string) => {
    return <>
      <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setDialog(id + "Reconcile UIDs")}>
        <ListItemIcon>
          <Icon icon={'bxs:cloud-upload'} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary={`Reconcile UIDs`} primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <ResponsiveDialog openDialog={dialog == id + "Reconcile UIDs"} subTitle="" setOpenDialog={setDialog} title="Reconcile IDs" >
        <TextField
          sx={{ mb: 2 }}
          select
          label="Type"
          fullWidth
          onChange={(e: any) => setReconsileType(e.target.value)}
          SelectProps={{
            native: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={reconsileType}
          variant="standard"
        >
          <option key="" value="">
            {"Select Type"}
          </option>
          <option key="" value={1}>
            {"Approved"}
          </option>
          <option key="" value={2}>
            {"Rejected"}
          </option>
        </TextField>
        <Box sx={{ mb: 2 }}>
          <Link to="/assets/csv/sample_uid.csv" target="_blank" download>Download Sample</Link>
        </Box>
        <CSVReader parserOptions={papaparseOptions} onFileLoaded={(data, fileInfo, originalFile): any => {
          setReconsileData(data);
        }} />
        <Button
          sx={{ mt: 4 }}
          variant="contained"
          disabled={!reconsileType || !reconsileData.length ? true : false}
          onClick={() => dispatch(ProjectReconsileData(id, { uid: reconsileData, type: reconsileType }, setMessageHandler))}
          startIcon={<Icon icon={plusFill} />}
        >
          Upload
        </Button>
      </ResponsiveDialog>
    </>
  }
  
  const suppliercolumns = [
    {
      field: 'supplierName', type: "string", headerName: 'Supplier Name', filterable: false, headerAlign: "left", align: 'left', flex: 1, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Link to={`/admin/supplier/${id}/edit`}>
          <Typography color="primary" >{value}</Typography>
        </Link>
      }
    },
    {
      field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 150, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['complete']}`;
      },
    },
    {
      field: 'approved', type: "string", headerName: 'Approved', filterable: false, width: 150, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['approved']}`;
      },
    },
    {
      field: 'supplierCPI', type: "string", headerName: 'Supplier CPI',editable: true, filterable: false, width: 150, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['supplierCPI']}`;
      },
    },
    {
      field: 'cost', type: "string", headerName: 'Actual Amount', filterable: false, width: 150, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `$ ${row['supplierCPI'] * row['approved']}`;
      },
    },
    { field: 'totalAccepted', type: "string", headerName: 'Admin Approved', editable: true, filterable: false, width: 200, headerAlign: "center", align: 'center' },
  ];

  const dialogActionsSupplier = (filename:string,id: string, title: any) => {
    return <Box>
      <IconButton onClick={() => setDialogSupplier(id)}>
      <Icon icon={'typcn:edit'} width={24} height={24} />
      </IconButton>
      <ResponsiveDialog openDialog={dialogSupplier == id} subTitle="" setOpenDialog={setDialogSupplier} title={title} >
        <DataGridRender
          onCellEditCommit={onCellEditCommit}
          height={supplierMaps.length > 1 ? supplierMaps.length * 150 : 250}
          width={1100}
          filterTypes={['export']}
          search=""
          filters="{}"
          exportFileName={filename}
          setSearch=""
          data={supplierMaps}
          metadata=""
          limit={limit}
          pageNo={pageNo}
          columns={suppliercolumns}
          hideFooterPagination={true}
          setPageNo={setPageNo}
          setLimit={setLimit}
          setFilters={setFilters}
        />
      </ResponsiveDialog>
    </Box>
  }


  const reloadAction = () => {
    dispatch(ProjectAllRequest(filters, pageNo, limit, status))
  }

  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 120, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Typography color="primary" >{value}</Typography>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'clienName', type: "string", headerName: 'Client Name', filterable: false, headerAlign: "left", align: 'left', width: 150, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'poNumber', type: "string", headerName: 'PO Number', filterable: false, headerAlign: "left", align: 'left', width: 120, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 100, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['complete'])}`;
      },
    },
    {
      field: 'approved', type: "string", headerName: 'Approved', filterable: false, width: 110, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['approved']}/${row['supplierApproved']}`;
      },
    },
    {
      field: 'totalDisqualified', type: "string", headerName: 'Supplier', filterable: false, width: 90, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsSupplier(row['projectCode']+"_"+row['projectName'],row._id, 'Supplier Mapped');
      },
    },
    {
      field: 'rejected', type: "string", headerName: 'Rejected', filterable: false, width: 90, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['rejected'])}`;
      },
    },

    {
      field: 'cpi', type: "string", headerName: 'CPI', filterable: false, width: 60, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['cpi'])}`;
      },
    },
    {
      field: 'rrRate', type: "string", headerName: 'A.Rate', filterable: false, width: 80, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['rrRate'])}`;
      },
    },
    {
      field: 'projectManger', type: "string", headerName: 'Manager', filterable: false, width: 120, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return <Label
          variant="ghost"
          color="primary"
        >
          {row['projectManger']}
        </Label>
      },
    },
    {
      field: 'projectStatus', headerName: 'Status', type: "number", width: 80, valueFormatter: ({ value }: any) => ProjectStatus.find(status => status.value == value)?.label,
      renderCell: (params: any) => {
        var status: any = ProjectStatus.find(status => status.value == params.value);
        return <Label
          variant="ghost"
          color={status?.color}
        >
          {sentenceCase(status.label)}
        </Label>
      }
    },
    { field: 'actions', headerName: '', width: 50, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['projectCode'] + "_" + row['projectName']}  clientApproved={row['approved']}   exportFileName="Project" reconsileAction={reconsileAction} cloneAction='' reconcileUpdateAction={reconcileUpdateAction} archievedUpdateAction={archievedUpdateAction} revertAction={revertAction} deleteAction="" exportSupplierMap="" exportAction={exportAction} />] },
  ];



  return (
    <Container maxWidth={false}>
      <TopBar title="Reconcile Projects" link="" buttonText="New Project" />
      <DataGridRender
        reloadAction={reloadAction}
        getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
        loading={loading}
        filterTypes={['client', 'user', 'export', 'search']}
        data={data}
        filters={filters}
        exportFileName="Projects"
        metadata={metadata}
        limit={limit}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}

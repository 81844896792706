import { Card, Container, IconButton, styled, Box } from '@mui/material';
import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TopBarForm from 'src/components/shared/TopBarForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'src/reducers';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { InvoiceDownloadRequest, InvoiceSingleRequest } from 'src/actions/invoiceActions';
import './pdfstyle.css'

const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
	},
	section: {
		flexGrow: 1,
	},
});

const CardStyle = styled(Card)(({ }) => ({
	width: '100%',
	padding: 30
}));

const ViewInvoice = () => {

	const { invoiceid }: any = useParams<any>();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { invoice } = useSelector(state => state.invoice);

	useEffect(() => {
		dispatch(InvoiceSingleRequest(invoiceid));
	}, [invoiceid]);

	return <Container maxWidth={false}>
		<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Box>
				<TopBarForm title="View Invoice" link="/admin/invoice/list" />
			</Box>
			<Box>
				<IconButton onClick={() => navigate(`/admin/invoice/generate/${invoiceid}/edit`)}>
					<EditIcon />
				</IconButton>
				<IconButton onClick={() => dispatch(InvoiceDownloadRequest(invoiceid))}>
					<FileDownloadIcon />
				</IconButton>
			</Box>
		</Box>
		<CardStyle>
			<Document>
				<Page size="A4" style={styles.page}>
					<div className="header">
						<h1 style={{ textAlign: "center", width: "100%" }}>Opinion Nation</h1>
					</div>
					<div className="bg-img">
					</div>
					<div className="info">
						<table style={{ width: "100%" }}>
							<tbody>
								<tr>
									<td width="70%">
									<div className="company-info">
                        <h3 style={{ margin: 0, padding: 0 }}>OPIONION NATION INC.</h3>
                        <p>10251 Sparks Sub Rd</p>
                        <p>Potosi MO 63664</p>
                        <p>USA</p>
                        <p>+1 (217) 823-0316</p>
                        <p>finances@opinion-nation.com</p>
                    </div>


									</td>
									<td>
										<div className="invoice-info">
											<h3 style={{ margin: 0, padding: 0 }}>
												{"INVOICE " + invoice.invoiceCode}
											</h3>
											<p>
												<strong>DATE:</strong>
												{invoice.currentDate}
											</p>
											<p>
												<strong>DUE DATE:</strong>
												{invoice.dueDate}
											</p>
											<p>
												<strong>TERMS Net:</strong>
												{invoice.netTerms} Days
											</p>
											<h4>BILL TO:</h4>
											<p>
												<strong>
													{invoice?.clientId?.company}
												</strong>
											</p>
											<p>
												{invoice?.clientId?.address1}
												{invoice?.clientId?.address2 ? ',' + invoice?.clientId?.address2 : ""}
											</p>
											<p>
												{invoice?.clientId?.city + ','}
												{invoice?.clientId?.state + ','}
												{invoice?.clientId?.zipCode + ','}
												{invoice?.clientId?.countryId?.name}
											</p>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="invoice-body">
						<table width="100%" className="invoice-body">
							<thead>
								<tr style={{ borderBottom: "2px solid #000" }}>
									<th align="left">
										<strong>S No.</strong>
									</th>
									<th align="left">
										<strong>Project Name</strong>
									</th>
									<th align="left">
										<strong>Amount Due</strong>
									</th>
								</tr>
							</thead>
							<tbody>
								{invoice.projects && invoice.projects.map((project, index) =>
									<tr>
										<td height={30}>
											{index + 1}.
										</td>
										<td height={30}>
											{project.poNumber + ' - ' + project.projectName}
										</td>
										<td height={30}>
											{"USD $" + project.total}
										</td>
									</tr>)}
							</tbody>
							<tfoot>
								<tr>
									<th colSpan={2} align="right" style={{ paddingRight: 50 }}>
										<strong>GRAND TOTAL</strong>
									</th>
									<th align="left">
										<strong>
											{"USD $" + invoice && invoice.projects && invoice.projects.reduce(function (acc, obj) { return acc + (obj.total || 0) }, 0)}
										</strong>
									</th>
								</tr>
							</tfoot>
						</table>
					</div>
					<div className="footer" style={{ marginTop: 20 }}>
						<p>Description- Online Market Research Services</p>
						<div className="bank-detail">
								<p><strong>BENEFICIARY BANK DETAILS(ONLY FOR BANKS IN USA)</strong></p>
								<p>Beneficiary Name: Opinion Nation Inc.</p>
								<p>Beneficiary Bank Routing Number: 071112066</p>
								<p>Beneficiary Bank Account Number: 8120001550</p>
								<p>Beneficiary Bank: Heartland Bank and Trust Company</p>
							</div>
							<div className="bank-address">
								<p><strong>BENEFICIARY BANK ADDRESS</strong></p>
								<p>401 N Hershey RD, Bloomington</p>
								<p> IL 61704,USA</p>
							</div>
							<div className="bank-detail">
								<p><strong>BENEFICIARY BANK DETAILS(FOR BANKS OUTSIDE USA)</strong></p>
								<p>Beneficiary Name: Heartland Bank and Trust Company</p>
								<p>Beneficiary Bank Routing Number: 043000096</p>
								<p>Beneficiary Bank Account Number: 4607661129</p>
								<p>Beneficiary Bank Swift Code: PNCCUS33 </p>
								<p>Beneficiary Bank: PNC Bank</p>
							</div>
							<div className="bank-detail">
								<p><strong>ADD INSTRUCTIONS</strong></p>
								<p>Further credit to  Opinion nation Inc Account number 8120001550</p>
							</div>
							<div className="bank-address">
								<p><strong>BENEFICIARY BANK ADDRESS</strong></p>
								<p>249 Fifth Avenue, Pittsburgh </p>
								<p> PA 15222,USA</p>
							</div>
					</div>
				</Page>
			</Document>
		</CardStyle>
	</Container>
}

export default ViewInvoice;
